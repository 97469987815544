// Import Froala RTE defaults
// --------------------------------------------------------
@import '../../lib/froala';

.rte-content, .content-default {
  font-size: 16px;
  line-height: 1.667;

  @include respond($break60) {
    font-size: 18px;
  }

  a {
    @include accentFocus;
    color: inherit;
    text-decoration: underline;
  }

  h1, h2 {
    @include headingPrimary;
    padding-bottom: 0.75em;
  }

  ul, ol, p {
    + h1, + h2 {
      margin-top: 2.031em;
    }
  }

  h3, h4 {
    padding-bottom: 1.278em;
    font-size: 18px;
    font-weight: $bold;
    line-height: 1.031;
  }

  ul, ol, p {
    + h3, + h4 {
      margin-top: 3.611em;
    }
  }

  p {
    margin: 0;

    + p {
      margin-top: $verticalSpace;
    }
  }

  ul + ul,
  ul + ol,
  ol + ul,
  ol + ol,
  p + ul,
  p + ol {
    margin-top: 1.05em;
  }

  ul, ol {
    margin: 0;
    margin-bottom: 1.5em;
  }

  ul {
    // Padding is flush
    padding-left: 18px;

    li {
      padding-left: 0.167em;

      + li {
        margin-top: 0.18em;
      }
    }
  }

  ol {
    @include listUnstyled;

    li {
      text-align: left;

      &::before {
        display: inline-block;
        width: 0.889em;
        text-align: left;
      }
    }
  }

  hr {
    margin: 2.222em 0 1.833em;
    border: 0;
    border-top: 1px solid $neutral20;
  }
}
