.content-block {
  @include containerBase;
  padding-bottom: 45px;

  @include respond($break60) {
    padding-bottom: 50px;
  }
}

.content-section {
  // Used inside content blocks to separate sections
  + .content-section {
    padding-top: 60px;

    @include respond($break40) {
      padding-top: 16vw;
    }

    @include respond($break110) {
      padding-top: 193px;
    }
  }
}

.content-hero {
  padding-bottom: 24px;
  margin-right: -$containerPaddingResp;
  margin-left: -$containerPaddingResp;

  @include respond($break60) {
    padding-bottom: 46px;
    margin-right: auto;
    margin-left: auto;
  }
}
