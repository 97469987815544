.year-nav-secondary {
  position: fixed;
  top: 42px;
  width: 100%;
  transition: opacity $durationSlow $timing $duration;

  @include respond($break40) {
    top: 35px;
  }

  @include respond($break70) {
    position: static;
    top: auto;
    width: auto;
  }

  // Weird selector to elegantly hide year-nav when hamburger is open
  .hamburger-open ~ main &,
  .search-open ~ main & {
    opacity: 0;
    transition: opacity $duration $timing;
  }

  .container {
    @include containerBase;
    padding-right: $containerPaddingResp;
    padding-bottom: 8px;
    padding-left: $containerPaddingResp;
    background-color: $neutralOffWhite;

    @include respond($break70) {
      background-color: transparent;
    }

    @include respond($break130) {
      padding-right: $containerPadding90;
      padding-left: $containerPadding90;
    }
  }

  .year-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include respond($break70, max) {
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;
    }

    @include respond($break70) {
      position: fixed;
      top: 83px;
      display: block;
      padding-left: 0;
      background-color: transparent;
    }

    @include respond($break90) {
      top: 91px;
    }

    @include respond($break110) {
      top: 95px;
    }

    @include respond($break130) {
      top: 105px;
    }

    .input-wrapper {
      position: absolute;
      top: 0;
      left: auto;

      .no-touchevents & {
        &:hover {
          &::after {
            opacity: 1;
          }

          .active::placeholder {
            color: transparentize($neutralBlack, 0.5);
          }

          .label {
            opacity: 1;
          }
        }
      }

      &::after {
        position: absolute;
        top: 100%;
        right: 11px;
        left: 8px;
        display: block;
        content: "";
        border-top: 1px dashed $neutralBlack;
        opacity: 0;
        transition: opacity $duration $timing;
      }
    }

    .active,
    .placeholder {
      min-width: 67px;

      @include respond($break60) {
        min-width: 80px;
      }
    }

    .label {
      position: absolute;
      top: calc(100% + 12px);
      display: none;
      width: 100%;
      margin: 0; // OD
      font-size: 12px;
      color: $neutral50;
      text-align: center;
      pointer-events: none;
      opacity: 0;
      transition: opacity $duration $timing;

      @include respond($break60) {
        display: block;
        font-size: 12px;
      }
    }

    // CABS appearance.scss
    .previous,
    .next {
      font-size: 9px;
      color: $neutralBlack;

      @include respond($break70) {
        position: absolute;
      }
    }

    .next {
      @include iconPost($arrowShortRight);
      @include accentFocus;
      margin-left: 12px;

      @include respond($break70) {
        top: 50%;
        right: -28px;
        margin-top: -5px;
        margin-left: 12px;
      }
    }

    .previous {
      @include iconPre($arrowShortLeft);
      margin-right: 12px;

      @include respond($break70) {
        top: 50%;
        left: -30px;
        margin-top: -5px;
        margin-right: 0;
      }
    }
  }
}
