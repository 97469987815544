.artwork-detail {
  $infoWidth: 910px;
  $colMinWidth: 155px;
  padding-top: 7px;

  @include respond($break40) {
    padding-top: 13px;
  }

  .container {
    padding-right: $containerPaddingResp;
    padding-left: $containerPaddingResp;

    @include respond($break130) {
      padding-right: $containerPadding90;
      padding-left: $containerPadding90;
    }
  }

  header {
    @include clearfix;
    position: relative;
    min-height: 48px;
    text-align: center;

    @include respond($break40) {
      min-height: 63px;
    }

    @include respond($break65) {
      min-height: 79px;
    }

    .pager {
      display: inline-block;

      @include respond($break65) {
        margin-top: 13px;
      }

      // Special iPad width breakpoint
      @include respond($break105) {
        display: none;
      }

      // Only show for devices with touch
      .no-touchevents & {
        display: none;
      }

      button {
        @include buttonUnstyled;
      }

      .label {
        @include labelPrimary;
        font-size: 11px;

        @include respond($break40) {
          font-size: 13px;
        }
      }

      .prev, .next {
        &.disabled {
          color: $neutral40;
        }
      }

      .prev {
        @include iconPre($arrowLeft);
        margin-right: 1px; // NP
        font-size: 9px;
      }

      .next {
        @include iconPre($arrowRight);
        margin-left: 1px; // NP
        font-size: 9px;
      }
    }

    .button-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .image {
    position: relative;
    z-index: 0;
    width: 100%;
    text-align: center;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      max-height: 65vh;
      margin: 0 auto;
    }

    .control {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .item-page-left, .item-page-right {
      @include buttonUnstyled;
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      padding: 0;
    }

    .item-page-left {
      left: 0;
      cursor: url('../images/arrow-circle-left.cur'), url('../images/arrow-circle-left.png'), pointer;

      @include respond(2dppx, min, resolution) {
        cursor: url('../images/arrow-circle-left2x.cur'), url('../images/arrow-circle-left.png'), pointer;
      }
    }

    .item-page-right {
      right: 0;
      cursor: url('../images/arrow-circle-right.cur'), url('../images/arrow-circle-right.png'), pointer;

      @include respond(2dppx, min, resolution) {
        cursor: url('../images/arrow-circle-right2x.cur'), url('../images/arrow-circle-right.png'), pointer;
      }
    }
  }

  .title {
    max-width: $infoWidth;
    padding: 0 12px;
    margin: 25px auto 0;
    font-size: 16px;
    font-weight: $regular;
    line-height: 1.5;
    text-align: center;

    @include respond($break70) {
      font-size: 19px;
    }
  }

  .meta {
    @include clearfix;
    max-width: $infoWidth;
    padding: 38px 0 100px;
    margin: 0 auto;

    @include respond($break50) {
      padding: 38px 12px 100px;
    }

    .label {
      display: block;
      width: 100%;
      font-size: 13px;
      font-weight: $medium;
      color: $neutral50;
      letter-spacing: 0.007em;

      @include respond($break50) {
        display: inline-block;
      }
    }

    // Currently these are the same
    // but use separate names to help semantics
    .info, .related {
      text-align: left;

      @include respond($break50) {
        float: left;
        width: 50%;
      }
    }

    .info {
      padding-right: 9.435vw;

      @include respond($break50) {
        padding-right: 50px;
        text-align: right;
      }

      + .info {
        padding-right: 0;
        margin-top: 10px;

        @include respond($break50) {
          margin-top: 0px;
        }
      }

      ul {
        @include listUnstyled;
        display: inline-block;
        text-align: left;
      }

      li + li {
        margin-top: 10px;

        @include respond($break50) {
          margin-top: 35px;
        }
      }

      p {
        margin: 2px 0 0;
        font-size: 16px;
        line-height: 1.35;

        @include respond($break50) {
          font-size: 17px;
        }
      }

      label + p {
        margin-top: 2px;
      }

      a {
        padding-bottom: 2px;
        line-height: 1.35;
        color: $neutralBlack;
        text-decoration: none;
        border-bottom: 1px dotted $neutralBlack;
      }
    }

    .tags, .series {
      display: block;
      padding-left: 0;
      margin-top: 10px;

      @include respond($break50) {
        padding-left: 50px;
        margin-top: 0;
      }

      ul {
        @include listUnstyled;
        display: flex;
        flex-flow: row wrap;
        margin-top: -3px;
        margin-left: -32px;

        @include respond($break50) {
          margin-top: -6px;
        }
      }

      label + ul {
        margin-top: -2px;
      }

      li {
        display: inline-block;
        margin: 6px 0 6px 32px;
      }

      a {
        padding-bottom: 2px;
        font-size: 16px;
        line-height: 1.35;
        color: $neutralBlack;
        text-decoration: none;
        border-bottom: 1px dotted $neutralBlack;

        @include respond($break50) {
          font-size: 17px;
        }
      }
    }

    .series {
      margin-top: 10px;

      @include respond($break50) {
        margin-top: 32px;
      }

      ul {
        display: inline-block;
        margin-left: 0;

        li {
          display: block;
          margin-left: 0;
        }
      }
    }
  }



  .details-wrapper {
    margin: 0 auto;

    @include respond($break50) {
      text-align: center;
    }

    &.empty-sketchbook {
      padding: 40px 0;
    }
  }

  .details {
    display: inline-block;
    max-width: $infoWidth;

    .title, .meta {
      margin-right: 0;
      margin-left: 0;
    }

    .title {
      display: inline-block;
    }

    .meta {
      @include respond($break50) {
        display: flex;
        flex-flow: row no-wrap;
        align-items: flex-start;
        justify-content: center;
      }

      .info, .related {
        @include respond($break50) {
          float: none;
          width: auto;
          max-width: none;
        }
      }

      .related {
        .tags, .series {
          display: block;
        }
      }
    }
  }

  .loader-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &.sketchbook {
    .breadcrumb-primary {
      text-align: left;
    }

    .title {
      padding-left: 0;
    }

    .sketch-meta-contain {
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 650px;
      padding-bottom: 60px;
    }

    .sketch-column {
      flex-basis: 50%;
      text-align: left;
    }

    .description {
      flex-basis: 100%;
      padding-bottom: 40px;
      font-size: 16px;
      text-align: left;
    }

    .carousel {
      padding: 25px 0;

      @include respond($break60) {
        width: 500px;
        margin: 0 auto;
      }

      .flickity-page-dots {
        position: relative;
      }

      .carousel-cell {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 500px;
        padding-right: 10px;
        padding-left: 10px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s linear,
          visibility 0.3s linear;

        @include respond($break40) {
          height: 500px;
        }

        @include respond($break60) {
          width: 500px;
          padding-right: 0;
          padding-left: 0;
        }

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }

        &.portrait {
          flex-direction: row;

          &.rotate-90 {
            transform: rotate(90deg);

            img {
              max-width: 500px;
            }
          }

          &.rotate-180 {
            transform: rotate(180deg);
          }

          &.rotate-270 {
            transform: rotate(270deg);

            img {
              max-width: 500px;
            }
          }
        }

        &.landscape {
          flex-direction: column;

          &.rotate-90 {
            transform: rotate(90deg);
          }

          &.rotate-180 {
            transform: rotate(180deg);

            img {
              max-height: 500px;
            }
          }

          &.rotate-270 {
            transform: rotate(270deg);

            img {
              max-height: 500px;
            }
          }
        }

        &.is-selected {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
