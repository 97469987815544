.content-grid {
  @include respond($break60) {
    display: flex;
    margin-left: -3.9vw;
  }

  @include respond($break110) {
    margin-left: -45px;
  }

  [class^='g-'] {
    width: 100%;

    @include respond($break60) {
      padding-left: 3.9vw;
    }

    @include respond($break110) {
      padding-left: 45px;
    }

    + [class^='g-'] {
      padding-top: 45px;

      @include respond($break60) {
        padding-top: 0;
      }
    }

    &.p-10 {
      @include respond($break60) {
        padding-top: 9.6vw;
      }

      @include respond($break110) {
        padding-top: 110px;
      }
    }

    &.p-20 {
      @include respond($break60) {
        padding-top: 17.5vw;
      }

      @include respond($break110) {
        padding-top: 200px;
      }
    }
  }

  .g-40 {
    @include respond($break60) {
      min-width: 50%;
    }

    @include respond($break90) {
      min-width: 42%;
    }
  }

  .g-50 {
    @include respond($break60) {
      min-width: 50%;
    }
  }

  .g-60 {
    @include respond($break60) {
      min-width: 50%;
    }

    @include respond($break90) {
      min-width: 58%;
    }
  }

  .nested-image {
    + .nested-image {
      margin-top: 45px;

      @include respond($break60) {
        margin-top: 32px;
      }

      @include respond($break90) {
        margin-top: 40px;
      }
    }
  }
}
