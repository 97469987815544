.content-text {
  @include clearfix;
  @include containerFocus;
  margin: 0 auto;

  // Show and hide appropriate nested content
  .content-nested {
    &.stacked {
      @include respond($break90) {
        display: none;
      }
    }

    &.nested {
      display: none;

      @include respond($break90) {
        display: block;
      }
    }
  }

  .nested-image {
    display: none;
    max-width: 498px;
    margin-bottom: 0;

    @include respond($break90) {
      display: block;
    }

    &.right {
      float: right;
      margin: 16px -135px 16px 48px;
    }

    &.left {
      float: left;
      margin: 16px 48px 16px -135px;
    }
  }

  + .nested-image {
    margin-top: 38px;
    margin-bottom: 0;

    @include respond($break90) {
      display: none;
    }
  }
}
