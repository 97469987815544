.logo-primary {
  @include accentFocus;
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 5;
  display: block;
  transition: opacity $duration $timing,
    visibility $duration $timing;

  &.with-dynamic {
    visibility: hidden;
    opacity: 0;
  }

  @include respond($break70) {
    top: 5px;
  }

  @include respond($break130) {
    top: 10px;
  }

  &.hidden {
    display: none;
  }

  svg {
    polygon, path, rect {
      @include accentSite(fill);
      fill-rule:evenodd;
      transition: fill $durationFast $timing;
      clip-rule:evenodd;
    }
  }

  .horizontal {
    @include respond($break70) {
      display: none;
    }

    svg {
      display: block;
      width: 71.25vw;
      min-width: 228px;
      max-width: 315px;
      height: auto;
      max-height: 15px; // For IE
    }
  }

  .stack {
    display: none;

    @include respond($break70) {
      display: block;
    }

    svg {
      @include respond($break70) {
        width: 140px;
        height: 50px;
      }

      @include respond($break90) {
        width: 169px;
        height: 59px;
      }
    }
  }
}
