.content-image {
  // Figure with .image wrapper, <img> and optional caption
  // Full width variant does not have this
  display: block;
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;
  text-align: center;

  @include respond($break130) {
    padding-right: $containerPadding70;
    padding-left: $containerPadding70;
  }

  .nested-image & {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .image img {
    width: 100%;
    max-width: 100%;
    height: auto;

    @include respond($break110) {
      max-width: $mediaWidth100;
    }
  }

  > a {
    display: block;
  }

  &.hero {
    .image img {
      width: auto;
      max-width: 100%;
      max-height: 450px;

      // Allow a max height on mobile screens as well
      @include respond($break60, max) {
        max-height: 52vh;
      }

      @include respond($break90) {
        max-width: $mediaWidth90;
      }

      @include respond($break70, min, height) {
        max-height: 60vh;
      }

      @include respond($break110) {
        max-width: 758px;
      }

      @include respond($break130) {
        max-width: 890px;
      }
    }
  }

  &.short {
    .image img {
      max-height: $sliderHeight20;

      @include respond($break65) {
        max-height: $sliderHeight40;
      }
    }
  }

  &.focus {
    .image img {
      max-width: 100%;

      @include respond($break60) {
        // Set a focus max width
        max-width: $containerWidthFocus;

        // Unless the image is nested
        .nested-image & {
          max-width: 100%;
        }
      }
    }
  }

  &.full {
    padding-right: 0;
    padding-left: 0;

    .image img {
      max-width: 100%;
    }
  }

  &.full {
    padding-right: 0;
    padding-left: 0;

    .image img {
      max-height: none;
    }
  }

  // Force a full width image even if image isn't wide enough
  &.full-force {
    padding-right: 0;
    padding-left: 0;

    .image {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  .image {
    position: relative;
    display: inline-block;
    max-width: 100%;

    &.linked {
      cursor: pointer;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  .caption-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80%;
    height: 0;
    overflow: hidden;
    text-align: right;
    transition: height $duration $timing;

    .touchevents & {
      position: static;
      display: block;
      width: 100%;
      height: auto;
      overflow: visible;
      text-align: left;
    }

    .caption {
      display: inline-block;
      padding: 0.364em 8px 0.273em 12px;
      font-size: 10px;
      color: $neutral50;
      letter-spacing: 0.04em;
      background-color: $neutralOffWhite;

      .touchevents & {
        display: block;
        padding: 0.455em 12px 0 0;
      }

      @include respond($break60) {
        font-size: 11px;
      }

      span {
        font-style: oblique;
      }
    }
  }
}
