.thumbnail-list-match {
  @include containerBase;
  padding: 8px $containerPaddingResp 0;

  @include respond($break40) {
    padding-top: 12px;
  }

  @include respond($break65) {
    padding-top: 19px;
  }

  @include respond($break130) {
    padding-right: $containerPadding90;
    padding-left: $containerPadding90;
  }

  &.landscape {
    .column-2 {
      @include respond($break50, max) {
        margin-left: 0;
      }
    }

    .list-header {
      @include respond($break50, max) {
        padding-left: 0;
      }
    }

    .image,
    .caption {
      // Can be a link or div
      display: block;

      @include respond($break50, max) {
        // Required to overwrite
        /* stylelint-disable-next-line declaration-no-important */
        order: inherit !important;
        width: 100%;
        padding-left: 0;
      }
    }

    .caption {
      @include respond($break50, max) {
        padding-bottom: 19px;
      }
    }

    .image img {
      max-height: 225px;
    }
  }

  .anchor {
    display: block;
    padding-bottom: 75px;
    margin-top: -75px;
    pointer-events: none;

    @include respond($break65) {
      padding-bottom: 100px;
      margin-top: -100px;
    }
  }

  .column-2 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10vw;

    @include respond($break50) {
      margin-left: -8vw;
    }

    @include respond($break65) {
      display: none;
    }

    .image-1 {
      order: 0;
    }

    .image-2 {
      order: 1;
    }

    .caption-1 {
      order: 2;
    }

    .caption-2 {
      order: 3;
    }
  }

  .horizontal-nav-reveal + .column-3 {
    padding-top: 19px;
  }

  .column-3 {
    display: none;
    flex-wrap: wrap;
    margin-left: -8vw;

    @include respond($break65) {
      display: flex;
    }

    @include respond($break100) {
      margin-left: -10vw;
    }

    @include respond($break130) {
      margin-left: -138px;
    }

    .image-1 {
      order: 0;
    }

    .image-2 {
      order: 1;
    }

    .image-3 {
      order: 2;
    }

    .caption-1 {
      order: 3;
    }

    .caption-2 {
      order: 4;
    }

    .caption-3 {
      order: 5;
    }
  }

  .list-header-trim {
    display: flex;
    padding-bottom: 14px;

    @include respond($break60) {
      display: none;
    }
  }

  .image,
  .caption {
    width: 50%;
    padding-left: 10vw;
    transition: $durationSlow $timing opacity;

    &.link {
      @include accentFocus;
      cursor: pointer;

      &:hover,
      &.hover {
        opacity: 0.5;
      }
    }

    @include respond($break50) {
      padding-left: 8vw;
    }

    @include respond($break65) {
      width: 33.333%;

      @include respond($break100) {
        padding-left: 10vw;
      }

      @include respond($break130) {
        padding-left: 138px;
      }
    }
  }

  .image {
    &.link:hover {
      + .caption {
        opacity: 0.5;
      }
    }

    .no-image {
      display: flex;
      width: 100%;
      height: 21vw;
      min-height: 120px;
      max-height: 300px;
      padding: 1em;
      border: 1px solid $neutral20;

      @include respond($break130) {
        height: 300px;
      }

      span {
        display: block;
        margin: auto;
        font-size: 14px;
        color: $neutral50;
        text-align: center;

        @include respond($break60) {
          font-size: 16px;
        }

        @include respond($break90) {
          font-size: 18px;
        }
      }
    }
  }

  .image {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      // Default to portrait height
      max-height: 420px;
      margin: 0 auto;
    }
  }

  .caption {
    padding-top: 12px;
    padding-bottom: 50px;
    font-size: 15px;
    line-height: 1.2;
    // Can be a link
    color: $neutralBlack;

    @include respond($break50) {
      padding-top: 14px;
      padding-bottom: 77px;
      font-size: 16px;
    }

    @include respond($break80) {
      font-size: 18px;
    }

    .label {
      @include labelPrimary;
      display: block;
      padding-bottom: 4px;
    }

    .title {
      padding-bottom: 0.444em;
      font-size: 1em;
      font-style: italic;
      font-weight: $regular;
      line-height: 1.167em;
    }

    .meta {
      .primary {
        padding-bottom: 1px; // NP
        margin: 0;
        font-size: 0.833em;
        line-height: 1.4em;

        @include respond($break50) {
          font-size: 15px;
        }

        + .secondary {
          margin-top: 7px;
        }
      }

      .secondary {
        margin: 0;
        font-size: 12px;
        color: $neutral50;
      }
    }

    .category,
    .categories,
    .note {
      display: inline-block;
      font-size: 12px;
      color: $neutral50;
    }

    .categories {
      @include listUnstyled;

      &.divider {
        &::after {
          margin: 0 5px 0 6px;
          content: "|";
        }
      }

      a {
        color: $neutral50;
      }

      li {
        display: inline;
        padding: 0;

        &::after {
          display: inline;
          margin-right: 0.12em;
          margin-left: -0.1em;
          content: ", ";
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
