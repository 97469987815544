.loader-bar {
  @keyframes loading70 {
    0% {
      width: 0;
    }

    100% {
      width: 70%;
    }
  }

  @keyframes loading90 {
    0% {
      width: 70%;
    }

    100% {
      width: 95%;
    }
  }

  display: block;
  width: 100%;
  height: 8px;
  background-color: $neutral20;

  .progress {
    @include accentSite(background-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    animation: loading70 5s $timing,
      loading90 10s $timing 4.98s;
    animation-fill-mode: forwards;
  }
}
