.logo-dynamic {
  // Each line of the logo is an svg, two layers,
  // back and front.
  $containerOffsetLeft: calc((100vw - #{$containerWidthMax}) / 2);
  pointer-events: none;

  .hamburger-open ~ & {
    svg {
      opacity: 0;

      @include respond($break70) {
        opacity: 1;
      }
    }

    &.tuck {
      g {
        fill: $neutralWhite;

        @include respond($break60) {
          @include accentSite(fill);
        }
      }
    }
  }

  .search-open ~ & {
    svg {
      opacity: 0;
    }
  }

  svg {
    opacity: 1;
    transition: opacity 0.6s $timing;
  }

  &.preload {
    svg {
      opacity: 0;
    }
  }

  // Important required to override JS position styles
  /* stylelint-disable declaration-no-important  */
  &.tuck {
    pointer-events: inherit;
    cursor: pointer;

    .logo-primary g {
      opacity: 1;
      transition: opacity 0.3s $timing 0.9s;
    }

    &.transition {
      .david, .hockney, .foundation {
        // Only transition max-width on mobile
        transition: left 1s $timing,
          top 1s $timing,
          max-width 1s $timing,
          width 1s $timing;

        @include respond($break70) {
          transition: left 1s $timing,
            top 1s $timing,
            width 1s $timing;
        }
      }
    }


    .david {
      top: 2.5vw;
      left: $containerPaddingResp !important;
      width: 21.563vw;
      min-width: 69px;
      max-width: 95px;

      svg {
        // For IE
        max-height: 15px;

        @include respond($break70) {
          max-height: 18px;
        }
      }

      @include respond(434px) {
        top: 10px;
      }

      @include respond($break70) {
        top: 23px;
        left: calc(#{$containerPaddingResp} + 10px) !important;
        width: 125px;
        max-width: none;
      }

      @include respond($break130) {
        top: 36px;
        left: calc((100vw - #{$containerWidthMax}) / 2 + #{$containerPadding90} + 10px) !important;
      }
    }

    .hockney {
      top: 2.5vw;
      left: calc(#{$containerPaddingResp} + 22.188vw) !important;
      width: 20.938vw;
      min-width: 67px;
      max-width: 92px;

      svg {
        // For IE
        max-height: 15px;

        @include respond($break70) {
          max-height: 18px;
        }
      }

      @include respond(434px) {
        top: 10px;
        left: calc(#{$containerPaddingResp} + 99px) !important;
      }

      @include respond($break70) {
        top: 43px;
        left: calc(#{$containerPaddingResp} + 47px) !important;
        width: 121px;
        max-width: none;
      }

      @include respond($break130) {
        top: 56px;
        left: calc((100vw - #{$containerWidthMax}) / 2 + #{$containerPadding90} + 48px) !important;
      }
    }

    .foundation {
      top: 2.5vw;
      left: calc(#{$containerPaddingResp} + 43.75vw) !important;
      width: 27.5vw;
      min-width: 88px;
      max-width: 121px;

      svg {
        // For IE
        max-height: 15px;

        @include respond($break70) {
          max-height: 18px;
        }
      }

      @include respond(434px) {
        top: 10px;
        left: calc(#{$containerPaddingResp} + 194px) !important;
      }

      @include respond($break70) {
        top: 63px;
        left: $containerPaddingResp !important;
        width: 160px;
        max-width: none;
      }

      @include respond($break130) {
        top: 76px;
        left: calc((100vw - #{$containerWidthMax}) / 2 + #{$containerPadding90}) !important;
      }
    }
  }
  /* stylelint-enable */

  &.fade-back {
    .logo-primary g {
      transition: opacity 2s $timing;
    }
  }

  .search-open + & {
    opacity: 0;
  }

  .david, .hockney, .foundation {
    // This may need to be fixed measurements depending
    // on browser
    svg {
      width: 100%;
      height: auto;
      max-height: 58px;
      transition: max-height 1s $timing;
    }

    g {
      @include accentSite(fill);
    }
  }

  // and a tracked position/scale/z-index
  .david {
    position: fixed;
    top: 60px;
    left: 25.313vw;
    width: 194px;
    max-width: 194px;

    @include respond($break40) {
      top: 63px;
      left: calc(#{$containerPaddingResp} + 66px);
      width: 275px;
      max-width: 275px;
    }

    @include respond($break60) {
      top: 69px;
      left: 46.706vw;
    }

    @include respond($break80) {
      top: 108px;
      width: 348px;
      max-width: none;
    }

    @include respond($break110) {
      left: 60.584vw;
    }

    @include respond($break130) {
      left: calc((100vw - #{$containerWidthMax}) / 2 + 831px);
    }
  }

  .hockney {
    position: fixed;
    top: 91px;
    left: 6.25vw;
    width: 188px;
    max-width: 188px;

    @include respond($break40) {
      top: 107px;
      width: 267px;
      max-width: 267px;
    }

    @include respond($break60) {
      top: 113px;
      left: 52vw;
    }

    @include respond($break80) {
      top: 164px;
      width: 338px;
      max-width: none;
    }

    @include respond($break90) {
      left: 62vw;
    }

    @include respond($break110) {
      left: 51.825vw;
    }

    @include respond($break130) {
      left: calc((100vw - #{$containerWidthMax}) / 2 + 709px);
    }
  }

  .foundation {
    position: fixed;
    top: 123px;
    left: 19vw;
    width: 248px;
    max-width: 248px;

    @include respond($break40) {
      top: 152px;
      left: calc(#{$containerPaddingResp} + 45px);
      width: 352px;
      max-width: 352px;
    }

    @include respond($break60) {
      top: 158px;
      left: 43.565vw;
    }

    @include respond($break80) {
      top: 221px;
      width: 446px;
      max-width: none;
    }

    @include respond($break110) {
      left: 58.102vw;
    }

    @include respond($break130) {
      left: calc((100vw - #{$containerWidthMax}) / 2 + 796px);
    }
  }
}
