// Base
// ----

// Applies a natural box layout model to all the things
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Sets text selection background color
// @include selection(#b3d4fd);

// Global
// --------------------------------------------------------
html {
  // Do you want to enable kerning and ligatures globally in webkit & gecko?
  // Beware that this occasionally causes width:auto elements to break to multiple lines in Chrome
  // It seems that the width of the element is computed prior to kerning
  // https://code.google.com/p/chromium/issues/detail?id=189755
  // text-rendering: optimizeLegibility;
  min-width: $break20;
  // Set the base font size here, which will correspond to 1em inside <body>
  font-size: 100%;
  // Do you want to force grayscale antialiasing globally in webkit & gecko?
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  // Set base font-family here
  @include fontBase;
  font-size: 1em;
  font-weight: $regular;
  line-height: $baseLineHeight;
  color: $neutralBlack;
  background-color: $neutralOffWhite;
}

// Typography
// --------------------------------------------------------
// Block-level elements
// --------------------------
%proto-heading {
  margin: 0;
  line-height: 1;
}

h1 {
  @extend %proto-heading;
  // font-size: $alpha;
}

h2 {
  @extend %proto-heading;
  // font-size: $beta;
}

h3 {
  @extend %proto-heading;
  // font-size: $gamma;
}

h4 {
  @extend %proto-heading;
  // font-size: $delta;
}

h5 {
  @extend %proto-heading;
  // font-size: $epsilon;
}

h6 {
  @extend %proto-heading;
  // font-size: $zeta;
}

// Inline elements
// --------------------------
a {
  // Do you want links to transition smoothly between states?
  // @include transition($duration color $timing);
  text-decoration: none;
  // color: $something;

  &:hover {
    // color: $something;
  }

  &:active {
    // color: $something;
  }

  &:visited {
    // color: $something;
  }
}

b,
strong {
  // font-weight: $bold;
}
