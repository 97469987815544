.kiosk-settings {
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;
  margin: 15vh auto 25vh;
  text-align: center;

  @include respond($break60) {
    padding: 0;
  }

  .continue {
    @include iconPost($arrowRight);
    padding-top: 2em;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;

    &:hover::after {
      transform: translateX(4px);
    }

    &::after {
      margin-left: 8px;
      font-size: 9px;
      vertical-align: middle;
      transition: transform $duration $timing;
    }
  }

  &.enabled {
    .continue {
      visibility: visible;
      opacity: 1;
    }
  }
}

.kiosk-button {
  @include buttonUnstyled;
  @include accentSite(border-color);
  padding: 2em;
  font-size: 18px;
  border: 1px solid transparent;
  transition: color $duration $timing,
    background-color $duration $timing;

  &:hover {
    @include accentSite(background-color);
    color: $neutralWhite;
  }

  .disabled & {
    .enabled {
      display: none;
    }
  }

  .enabled & {
    .disabled {
      display: none;
    }
  }
}
