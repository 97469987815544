@import '../../lib/flickity';

.content-slider {
  cursor: url('../images/drag.cur'), url('../images/drag.png'), move;

  @include respond(2dppx, min, resolution) {
    // Hi dpi cursor here
    cursor: url('../images/drag2x.cur'), url('../images/drag.png'), move;
  }

  .slider {
    @include containerBase;
    height: $sliderHeight20;
    overflow: hidden;

    @include respond($break65) {
      height: $sliderHeight40;
    }

    .flickity-viewport, .flickity-viewport.is-pointer-down {
      cursor: url('../images/drag.cur'), url('../images/drag.png'), move;

      @include respond(2dppx, min, resolution) {
        cursor: url('../images/drag2x.cur'), url('../images/drag.png'), move;
      }
    }

    &.no-drag {
      .flickity-viewport, .flickity-viewport.is-pointer-down {
        cursor: default;
      }
    }

    &.dragging .slide{
      pointer-events: none;
    }
  }

  .slide {
    height: 100%;
    padding-left: 40px;

    &:first-child {
      padding-left: 0;
    }

    .image {
      display: inline-block;
      height: 100%;
      background-color: $neutral15;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
      opacity: 0;
      transition: opacity $durationSlow $timing;

      &.flickity-lazyloaded {
        opacity: 1;
      }
    }
  }

  .caption {
    @include captionPrimary;
    @include containerFocus;
    margin: 0 auto;
    margin-top: 18px;
    color: $neutral50;

    a {
      color: $neutralBlack;
    }
  }
}
