.chronology-detail {
  padding-top: 45px;

  @include respond($break90) {
    padding-top: 53px;
  }

  @include respond($break110) {
    padding-top: 30px;
  }

  @include respond($break130) {
    padding-top: 43px;
  }
}
