// Variables
//----------

// Colors
// --------------------------------------------------------
// Neutrals
$neutral00: #ffffff;
$neutral10: #faf8f7; // Neutral off white
$neutral12: #edebeb;
$neutral15: #e3e5e5;
$neutral20: #cFcfd2; // iPad slider merge #DAD9D9
$neutral40: #b0b0b0;
$neutral50: #6e6e71; // merge #6a6a6a #696666
$neutral60: #50505A;
$neutral80: #2f2e2e;
$neutral100: #141419; // Neutral off black, merge #121212 and #000000

$neutralWhite: $neutral00;
$neutralOffWhite: $neutral10;

$neutralBlack: $neutral100;

// Accent Colors
// Built as a list and can be pulled out with nth
// Note that scss list index starts at 1
// I.E. nth($accent, 3) == #C77751
$accentPrimary: (
  #da89aa, // 1
  #e29268, // 2
  #ad94de, // 3
  #ba6796, // 4
  #62b59f  // 5
);

$accentSecondary: (
  #e9ddce, // 1
  #a9d1d3, // 2
  #96cbb2, // 3
  #e7e4cd, // 4
  #e6e0e0, // 5
  #e9ddce, // 6
  #a9d1d3, // 7
  #96cbb2, // 8
  #e7e4cd, // 9
  #e6e0e0  // 10
);
// Measurements
// --------------------------------------------------------
// Widest possible content width (except for special footer)
$containerWidthMax: 1370px;
$containerWidth90: 1305px;
// Container widths
$containerWidthFooter: 1500px;
$containerWidthFocus: 600px;

// Container Paddings
$containerPaddingResp: 5vw;
$containerPadding100: 110px;
$containerPadding90: 95px;
$containerPadding70: 72px;
$containerPadding20: 20px;

$siteHeaderWide: 68px;

$headerBumpNarrow: 45px;
$headerBumpMax: 110px;

$baseLineHeight: 1.667;
// Base paragraph spacing
$verticalSpace: 1.45em;

// Media Measurements
$mediaWidth100: 1070px;
$mediaWidth90: 960px;

$sliderHeight20: 240px;
$sliderHeight40: 385px;
$sliderHeight90: 960px;

$durationFast: 0.1s;
$duration: 0.2s;
$durationSlow: 0.5s;
$timing: ease;
$timingBounceBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$columnContentWidths: (
  45: 450,
  40: 430,
  35: 380,
  30: 300,
  25: 280,
);

// Font Weights
// --------------------------------------------------------
$light: 300;
$regular: 400;
$medium: 600;
$bold: 700;
$black: 900;

// Responsive breakpoints
// --------------------------------------------------------
$break130: $containerWidthMax;
$break120: 1215px;
$break110: 1150px;
$break105: 1030px;
$break100: 1020px;
$break90: 960px;
$break80: 830px;
$break70: 760px;
$break65: 670px;
$break60: 640px;
$break50: 530px;
$break40: 475px;
// $break30: ;
$break20: 320px;
// $break10: ;

// Focus
// --------------------------------------------------------
$focusOutlineWidth: 2px;
