.content-section-anchor {
  .container {
    @include containerBase;
    padding: 0 $containerPaddingResp 20px;

    @include respond($break70) {
      padding: 0 $containerPaddingResp 35px;
    }

    @include respond($break110) {
      padding: 0 $containerPaddingResp 18px;
    }

    @include respond($break120) {
      position: relative;
      max-width: $containerWidthMax;
      padding: 0 $containerPadding20 0 0;
      margin: 0 auto;
    }

    h3 {
      font-size: 12px;
      font-weight: $regular;
      line-height: 1.27;
      color: $neutral50;
      letter-spacing: 0.054em;

      @include respond($break110) {
        text-align: right;
      }

      @include respond($break120) {
        position: absolute;
        right: $containerPaddingResp;
        max-width: 150px;
        text-align: right;
      }

      @include respond($break130) {
        right: 48px;
        max-width: 220px;
      }

      &::after {
        display: inline-block;
        width: 34px;
        margin-left: 4px;
        content: '';
        border-bottom: 1px solid $neutral50;

        @include respond($break110) {
          display: none;
        }
      }

      &::before {
        display: none;
        width: 34px;
        margin-right: 5px;
        content: '';
        border-bottom: 1px solid $neutral50;

        @include respond($break110) {
          display: inline-block;
        }
      }
    }
  }

  + .full, + .natural.over-900px {
    @include respond($break120) {
      padding-top: 36px;
    }
  }
}
