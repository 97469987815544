.gdpr-banner {
  width: 100%;
  color: $neutral100;
  background-color: $neutral10;
  box-shadow: 0px 0px 15px 0px rgba($neutral100, 0.2);

  &__button {
    text-decoration: none;
    box-shadow: none;

    &--opt-out {
      text-transform: uppercase;
      background-color: $neutral40;
    }

    &--opt-in {
      color: $neutral100;
      text-transform: uppercase;
      @include accentSite(background-color);
    }

    &--dismiss {
      color: $neutral50
    }
  }
}
