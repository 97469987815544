// Two column page wrapper with flush right side
.two-column-flush {
  max-width: $containerWidthMax;
  margin: 0 auto;

  .container {
    padding-right: $containerPaddingResp;
    padding-left: $containerPaddingResp;

    @include respond($break130) {
      padding-right: $containerPadding90;
      padding-left: $containerPadding90;
    }

    &.grid {
      @include respond($break60) {
        display: flex;
        justify-content: space-between;
        padding-right: inherit;
        margin-left: -4vw;
      }

      @include respond($break90) {
        margin-left: -7.664vw;
      }

      @include respond($break130) {
        margin-left: -105px;
      }
    }
  }

  .col {
    width: 100%;

    @include respond($break60) {
      padding-left: 4vw;
    }

    @include respond($break90) {
      padding-left: 7.664vw;
    }

    @include respond($break130) {
      padding-left: 105px;
    }

    .fixed {
      @include respond($break60) {
        position: fixed;
        width: 40vw;
        max-width: 545px;
        margin-top: 10px;
      }
    }

    &.left {
      position: relative;
    }

    &.right {
      min-width: 46%;

      @include respond($break60) {
        padding-top: 58px;
      }
    }
  }
}
