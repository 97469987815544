.column-content {
  .content-block {
    padding-bottom: 58px;

    @include respond($break60) {
      padding-bottom: 80px;
    }

    @include respond($break110) {
      padding-bottom: 145px;
    }
  }

  .content-text {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}
