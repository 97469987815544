.block-hero {
  .container {
    display: flex;
    flex-wrap: wrap;
    max-width: $containerWidth90;
    padding-top: 4px;
    padding-bottom: 47px;
    margin: 0 auto;

    @include respond($break60) {
      padding-right: $containerPaddingResp;
      padding-left: $containerPaddingResp;
    }

    @include respond($break130) {
      padding-right: $containerPadding90;
      padding-left: $containerPadding90;
    }

    &.wide {
      // Wait longer to escape padding
      padding-right: 0;
      padding-left: 0;

      @include respond($break70) {
        padding-right: $containerPaddingResp;
        padding-left: $containerPaddingResp;
      }

      @include respond($break130) {
        padding-right: $containerPadding90;
        padding-left: $containerPadding90;
      }

      .body {
        order: 1;
        width: 100%;

        @include respond($break70) {
          order: 0;
          width: 40%;
        }

        @include respond($break90) {
          width: 33.333%;
        }
      }

      .description {
        padding-right: $containerPaddingResp;
        padding-left: $containerPaddingResp;

        @include respond($break70) {
          padding-right: 0;
          padding-left: 0;
        }

        @include respond($break110) {
          padding-left: 48px;
        }
      }

      .image {
        width: 100%;
        padding-left: 0;

        @include respond($break70) {
          width: 60%;
          padding-left: 5vw;
        }
        @include respond($break90) {
          width: 66.667%;
        }
      }
    }

    &.jumbo {
      padding-right: 0;
      padding-left: 0;

      @include respond($break70) {
        padding-right: $containerPaddingResp;
        padding-left: $containerPaddingResp;
      }

      @include respond($break130) {
        padding-right: $containerPadding90;
        padding-left: $containerPadding90;
      }

      .image {
        width: 100%;
        padding-left: 0;
      }

      .body {
        order: 2;
        width: 100%;

        @include respond($break70) {
          display: flex;
          align-items: flex-start;
          padding-top: 35px;
        }
      }

      .title {
        @include respond($break70) {
          width: 50%;
          padding-bottom: 48px;
        }
      }

      .description {
        padding-right: $containerPaddingResp;
        padding-left: $containerPaddingResp;

        @include respond($break70) {
          width: calc(50% + 3.25vw);
          padding-top: 0;
          padding-right: 0;
          padding-left: 6.5vw;
        }

        @include respond($break90) {
          width: calc(50% + 50px);
          padding-left: 100px;
        }
      }
    }
  }

  .image {
    width: 100%;
    padding-bottom: 30px;

    img {
      display: block;
    }

    figcaption {
      display: none;
    }

    @include respond($break60) {
      order: 1;
      width: 50%;
      padding-left: 5vw;
    }

    @include respond($break70) {
      width: 55%;
      padding-bottom: 0;
    }

    @include respond($break110) {
      width: 60%;
    }

    @include respond($break130) {
      padding-left: 87px;
    }
  }

  .body {
    width: 100%;

    @include respond($break60) {
      width: 50%;
    }

    @include respond($break70) {
      width: 45%;
    }

    @include respond($break110) {
      width: 40%;
    }
  }

  .title {
    min-height: 141px;
    padding: 30px $containerPaddingResp;
    color: transparentize($neutralBlack, 0.3);

    &:not([class*='bg-accent-']) {
      // Default color
      background-color: nth($accentSecondary, 5);
    }

    @include respond($break60) {
      padding: 30px 28px;
    }

    @include respond($break80) {
      min-height: 200px;
    }

    @include respond($break110) {
      min-height: 248px;
      padding: 44px 48px;
    }

    .date {
      @include labelPrimary;
      padding-bottom: 8px;
    }

    h1 {
      font-size: 22px;
      font-weight: $light;
      line-height: 1.481;

      @include respond($break60) {
        font-size: 25px;
      }

      @include respond($break80) {
        font-size: 27px;
      }
    }
  }

  .description {
    padding-top: 28px;
    padding-right: $containerPaddingResp;
    padding-left: $containerPaddingResp;

    @include respond($break60) {
      padding-right: 0;
      padding-left: 0;
    }

    @include respond($break90) {
      padding-left: 28px;
    }

    @include respond($break110) {
      padding-left: 48px;
    }

    .label {
      @include labelPrimary;
      margin-bottom: 1em;
      color: $neutral50;
    }

    .content-default {
      line-height: 1.556;
    }
  }

  .image {
    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      @include captionPrimary;
      margin-top: 5px;
    }
  }
}
