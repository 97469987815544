.block-nav-secondary {
  @include containerBase;
  padding: 0 $containerPaddingResp;

  .horizontal-nav-fixed + &,
  .horizontal-nav-reveal + & {
    @include respond($break60) {
      display: none;
    }
  }

  ul {
    @include listUnstyled;
    @include accentSite(background-color);
    padding: 13px 0 21px;
  }

  a {
    display: block;
    padding: 0.278em 0;
    font-size: 18px;
    color: $neutralWhite;
    text-align: center;
    letter-spacing: 0.059em;
  }
}
