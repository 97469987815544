@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.overlay-secondary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 70px;
  overflow-y: scroll;
  visibility: hidden;
  background-color: $neutralOffWhite;
  opacity: 0;
  transition: opacity $duration $timing,
    visibility $duration $timing;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @include respond($break70) {
    background-color: transparentize($neutralWhite, 0.05);
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  .container {
    padding-right: $containerPaddingResp;
    padding-left: $containerPaddingResp;

    @include respond($break130) {
      padding-right: $containerPadding90;
      padding-left: $containerPadding90;
    }
  }

  .header {
    position: relative;
    padding: 15px 0 28px;
    text-align: left;

    @include respond($break70) {
      padding: 32px 0 70px;
    }

    .title {
      @include labelPrimary;
      font-size: 11px;

      @include respond($break70) {
        font-size: 17px;
      }
    }

    .utility {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 50; // Local z-index
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 12px;
      padding-bottom: 50px;
      background-color: $neutralOffWhite;

      @include respond($break70) {
        position: absolute;
        top: -20px;
        right: 0;
        left: auto;
        z-index: 0;
        justify-content: flex-end;
        width: auto;
        padding: 0;
        background-color: transparent;
      }
    }

    .button {
      @include buttonUnstyled;
      @include accentSite(background-color);
      order: 1;
      padding: 0.438em 47px;
      margin-left: 6px;
      font-size: 16px;
      color: $neutralWhite;
      transition: background-color $duration $timing;

      @include respond($break70) {
        order: inherit;
        padding: 0.333em 26px;
        margin-right: 32px;
        margin-left: 0;
        font-size: 18px;
      }

      &.disabled {
        background-color: $neutral20;
      }
    }

    .button-bare {
      @include buttonUnstyled;
      @include accentFocus;
      padding: 0.438em 47px;
      margin-right: 6px;
      font-size: 16px;
      color: $neutralWhite;
      background-color: $neutral20;

      // Only bare on desktop
      @include respond($break70) {
        padding: 0;
        margin-right: 25px;
        font-size: 13px;
        color: $neutralBlack;
        letter-spacing: 0.054em;
        background-color: transparent;
      }
    }

    .button-close {
      @include buttonUnstyled;
      @include iconPost($x);
      @include accentFocus(':after');
      position: fixed;
      top: 14px;
      right: $containerPaddingResp;
      z-index: 50; // Local z-index
      font-size: 15px;
      color: $neutral50;
      cursor: pointer;
      transition: color $duration $timing;

      @include respond($break70) {
        @include iconPost($xCircle);
        position: static;
        display: block;
        width: 56px;
        height: 56px;
        font-size: 56px;
        color: $neutralBlack;

        &:hover {
          color: $neutral50;
        }

        &::after {
          vertical-align: top;
        }
      }
    }
  }
}

.fade-enter .overlay-secondary {
  animation: fadeIn $durationSlow forwards;
}

.fade-exit .overlay-secondary {
  animation: fadeOut $durationSlow forwards;
}
