.form-primary {
  @mixin formLabelText {
    font-size: 13px;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 0.115em;
  }

  .select {
    @include iconPost($caretDown);
    position: relative;
    display: block;
    margin-bottom: 18px;

    &::after {
      position: absolute;
      top: 50%;
      right: 18px;
      margin-top: -4px;
      font-size: 7px;
    }

    label {
      @include formLabelText;
      position: absolute;
      top: 50%;
      left: 16px;
      margin-top: -0.7em;
      pointer-events: none;
    }

    select {
      @include formLabelText;
      display: inline-block;
      width: 100%;
      padding: 1.231em 51px 1.077em 16px;
      cursor: pointer;
      background: $neutralWhite;
      border: 1px solid $neutralWhite;
      border-radius: 0;
      outline: 0;
      appearance: none;

      &:focus {
        @include accentFocus;
      }

      &:focus:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $neutralBlack;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  input[type='text'] {
    min-height: 56px;
    padding: 0 14px;
    font-size: 18px;
    color: $neutralBlack;
    background: $neutralWhite;
    border: 1px solid $neutralWhite;
    border-radius: 0;
    outline: 0;
    transition: border-color $duration $timing;
    appearance: none;

    &:focus {
      @include accentSite(border-color);
    }

    &::placeholder {
      @include formLabelText;
      font-size: 13px;
      color: transparentize($neutralBlack, 0.6);
    }
  }

  .input-range,
  .resettable > button {
    @include formLabelText;
    display: block;
    width: 100%;
    padding: 1.231em 16px 1.077em;
    margin-bottom: 18px;
    background-color: $neutralWhite;
  }

  .input-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: text;

    .left {
      display: flex;
      align-items: flex-start;
    }

    .input-wrapper {
      position: relative;
      min-width: 5.846em;
      height: 1.385em;
      margin-top: 0.231em;
      margin-left: 8px;
      border-bottom: 1px dashed $neutral40;

      .placeholder,
      .active {
        @include formLabelText;
        position: absolute;
        top: 0;
        left: 0;
        min-height: 0; // OD
        padding: 0;
        background: transparent; // OD
        border: 0;
      }

      .placeholder {
        opacity: 0.2;
      }
    }

    .note {
      padding-left: 8px;
      font-size: 12px;
      font-weight: $regular; // OD
      line-height: 1;
      color: $neutral50;
      text-align: right;
      text-transform: none;
      letter-spacing: 0.054em; // OD

      @include respond($break70) {
        display: none;
      }

      @include respond($break110) {
        display: block;
        font-size: 11px;
      }
    }
  }

  .resettable {
    position: relative;

    > button {
      @include formLabelText;
      @include buttonUnstyled;
      @include accentFocus;
      position: relative;
      padding-right: 51px;
      text-align: left;
      background-color: $neutralWhite; // OD

      @include respond($break70) {
        padding-right: 10px;
      }

      @include respond($break110) {
        padding-right: 51px;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -4px;
      font-size: 7px;
      pointer-events: none;

      &.hamburger {
        @include iconPost($hamburgerThin);
      }
    }

    .reset {
      @include accentSite(color);
      @include accentFocus;
      position: absolute;
      top: 50%;
      right: 16px;
      z-index: 50; // Local z-index
      display: block;
      margin-top: -0.8em;
      font-size: 12px;
      font-weight: $regular;
      text-transform: none; // OD
      letter-spacing: 0.054em;
      cursor: pointer;

      @include respond($break70) {
        display: none;
      }

      @include respond($break110) {
        display: block;
      }
    }
  }

  .text-search {
    position: relative;
    margin-bottom: 15px;

    input[type='text'] {
      @include respond($break90, max) {
        padding-right: 38px;
      }
    }

    button[type='submit'] {
      @include buttonUnstyled;
      @include iconPost($magnify);
      @include accentSite(color);
      @include accentFocus;
      position: absolute;
      top: 50%;
      right: 14px;
      display: inline-block;
      min-width: 19px;
      min-height: 22px;
      padding: 0;
      margin-top: -10px;
      font-size: 19px;
      line-height: 1;
      appearance: none;

      @include respond($break90) {
        top: 50%;
        right: auto;
        left: -42px;
        padding: 0;
        font-size: 23px;
      }
    }
  }

  .submit {
    @include buttonUnstyled;
    padding: 0.588em 2em 0.824em;
    font-size: 17px;
    color: $neutralWhite;
    @include accentSite(background-color);

    &.disabled {
      background-color: $neutral20;
    }
  }
}
