.error-block {
  max-width: 440px;
  padding: 25.9vw 5.313vw 21.8vw;
  margin: 0 auto;
  text-align: center;

  @include respond($break40) {
    max-width: 398px;
    padding-right: 0;
    padding-left: 0;
  }

  @include respond($break70) {
    padding-top: 20.82vw;
  }

  @include respond($break100) {
    padding-top: 212px;
    padding-bottom: 222px;
  }

  @include respond($break130) {
    padding-top: 222px;
    padding-bottom: 232px;
  }

  header {
    font-size: 35px;
    font-weight: $light;
    line-height: 1.25;
    color: $neutral80;

    hr {
      display: block;
      width: 30px;
      height: 5px;
      margin: 16px auto 0;
      background-color: nth($accentSecondary, 2);
      border: 0;
    }
  }

  p {
    font-size: 17px;
    color: $neutral50;
  }

  a {
    display: inline-block;
    margin-top: 40px;
    font-size: 18px;
    color: $neutral100;
    text-decoration: none;
    border-bottom: 1px dotted $neutral100;
  }
}
