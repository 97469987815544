.dropdown-nav {
  position: fixed;
  top: 31px;
  right: 32px;
  display: none;
  padding-top: 33px;
  visibility: hidden;
  opacity: 0;
  transition: opacity $duration $timing 100ms,
    visibility $duration $timing 100ms;

  @include respond($break70) {
    right: calc(#{$containerPaddingResp} + 36px);
    display: block;
  }

  @include respond($break130) {
    top: 39px;
    right: calc((100vw - #{$containerWidthMax}) / 2 + 85px);
  }

  &:hover,
  .dropdown-open-collection &.collection,
  .dropdown-open-resources &.resources,
  .dropdown-list-collection:focus-within &.collection,
  .dropdown-list-resources:focus-within &.resources {
    // Bump the z-index so active is always on top
    z-index: 210;
    visibility: visible;
    opacity: 1;
    transition: opacity $duration $timing, visibility $duration $timing;
  }

  &.resources {
    ul::before {
      right: 40px;
      left: auto;

      @include respond($break80) {
        right: 60px;
      }
    }
  }

  .dropdown-toggle {
    @include iconPost($caretDown);

    &::after {
      padding-left: 6px;
      margin-top: -2px;
      font-size: 7px;
      vertical-align: middle;
    }
  }

  .list {
    @include listUnstyled;
    position: relative;
    width: 248px;
    padding: 21px 0 22px;
    background-color: $neutral12;

    // Top Triangle

    &::before {
      position: absolute;
      top: -13px;
      left: 50%;
      margin-left: -13px;
      content: '';
      border-right: 13px solid transparent;
      border-bottom: 13px solid $neutral12;
      border-bottom-color: $neutral12;
      border-left: 13px solid transparent;
      transition: $duration $timing border-bottom-color;
    }

    li {
      display: block;
      margin-left: 0;

      &:hover {
        a {
          @include accentSite(color);
        }
      }

      + li {
        margin-top: 4px;
        margin-left: 0;
      }
    }

    a {
      display: block;
      padding: 0 2.667em;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.778;
      color: $neutralBlack;
      text-align: left;
      text-transform: none;
      letter-spacing: normal;

      border-bottom: 0;
      transition: $duration $timing background-color;

      @include respond($break80) {
        font-size: 17px;
      }
    }
  }
}
