.pagination {
  @include containerBase;
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;

  @include respond($break130) {
    padding-right: $containerPadding90;
    padding-left: $containerPadding90;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1150px;

    @include respond($break60) {
      position: relative;
      display: block;
      text-align: center;
    }
  }

  .arrow-left, .arrow-right {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: $black;
    color: $neutralBlack;

    &::before, &::after {
      font-size: 9px;
      vertical-align: middle;
    }

    @include respond($break60) {
      position: absolute;
      top: 50%;
      display: flex;
      margin-top: -20px;
    }
  }

  .arrow-left {
    @include iconPre($arrowLeft);
    left: 0;

    &::before {
      margin-right: 15px;
    }
  }

  .arrow-right {
    @include iconPost($arrowRight);
    right: 0;

    &::after {
      margin-left: 15px;
    }
  }

  .divider {
    width: 1px;
    height: 34px;
    margin: 0 29px;
    background-color: $neutral20;

    @include respond($break60) {
      display: none;
    }
  }

  .trim {
    @include trimLink;
    display: none;

    @include respond($break60) {
      display: inline-block;
    }
  }
}
