@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.overlay-primary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: transparentize($neutral10, 0);
  transition: background-color $durationSlow $timing;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &.focused {
    background-color: transparentize($neutral10, 0.2);
  }

  .button-close {
    // Button lives in container
    @include buttonUnstyled;
    @include accentFocus;
    @include iconPre($xCircle);
    width: 39px;
    height: 39px;
    padding: 0;
    margin: 0;

    @include respond($break40) {
      width: 47px;
      height: 47px;
    }

    @include respond($break65) {
      width: 58px;
      height: 58px;
    }

    &::before {
      font-size: 37px;
      color: $neutralBlack;

      @include respond($break40) {
        font-size: 45px;
      }

      @include respond($break65) {
        font-size: 56px;
      }
    }
  }
}

.fade-enter .overlay-primary {
  animation: fadeIn $durationSlow forwards;
}

.fade-exit .overlay-primary {
  animation: fadeOut $durationSlow forwards;
}
