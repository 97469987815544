.list-header-primary {
  @include containerBase;
  padding: 0 $containerPaddingResp 16px;

  @include respond($break80) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 $containerPaddingResp;
  }

  @include respond($break90) {
    padding: 16px $containerPaddingResp 0;
  }

  @include respond($break130) {
    padding: 29px $containerPadding70 0 $containerPadding90;
  }

  &.flex-60 {
    @include respond($break60) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &.width-90 {
    max-width: $containerWidth90;
    padding: 0 $containerPaddingResp 14px;

    @include respond($break60) {
      padding: 0 $containerPaddingResp 30px;
    }
  }

  .heading-secondary {
    @include headingSecondary;
    display: none;

    @include respond($break60) {
      display: block;
      font-size: 24px;
    }

    @include respond($break130) {
      min-height: 38px;
    }
  }

  .text-input-trim {
    margin-bottom: 10px;

    &.narrow input {
      width: 290px;
    }

    input {
      width: 335px;
      max-width: 100%;
    }
  }

  .utility {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;

    @include respond($break40) {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    @include respond($break60) {
      font-size: 16px;
    }

    @include respond($break105) {
      font-size: 18px;
    }

    .filter, .sort {
      @include respond($break40) {
        margin-left: 35px;
      }

      @include respond($break90) {
        margin-left: 47px;
      }
    }
  }

  .input-select-secondary {
    display: inline-block;
  }
}

.list-header-secondary {
  @include containerBase;
  padding: 10px $containerPaddingResp 16px;

  @include respond($break80) {
    display: flex;
    justify-content: space-between;
    padding: 0 $containerPaddingResp 8px;
  }

  @include respond($break90) {
    padding: 16px $containerPaddingResp 12px;
  }

  @include respond($break130) {
    padding: 16px $containerPadding70 12px $containerPadding90;
  }

  .title {
    @include headingSecondary;

    @include respond($break60) {
      font-size: 24px;
    }
  }

  .description {
    font-size: 16px;
    color: $neutral50;

    @include respond($break80) {
      max-width: 350px;
      text-align: right;
    }

    @include respond($break90) {
      max-width: 100%;
      font-size: 18px;
    }

    p {
      margin: 0;
    }
  }
}
