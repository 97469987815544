.left-column-nav-primary {
  // Left column page
  @include containerBase;
  padding: 0 $containerPaddingResp;

  @include respond($break70) {
    display: flex;
    padding-top: 14px;
  }

  @include respond($break110) {
    padding: 10px 165px 0 $containerPaddingResp;
  }

  @include respond($break130) {
    padding: 10px 165px 0 $containerPadding90;
  }

  .navigation {
    @include respond($break70) {
      min-width: 31vw;
    }

    @include respond($break110) {
      min-width: 360px;
    }

    .content-page-nav-fixed {
      display: none;

      @include respond($break70) {
        display: block;
      }
    }
  }

  .main {
    @include respond($break70) {
      padding-left: 6vw;
    }

    @include respond($break110) {
      padding-left: 7.3vw;
    }

    @include respond($break130) {
      padding-left: 100px;
    }
  }

  .content-page-nav-fixed {
    @include respond($break70) {
      display: none;
    }
  }
}

.left-column-nav-secondary {
  @include containerBase;
  padding: 0 $containerPaddingResp;

  @include respond($break65) {
    display: flex;
  }

  @include respond($break130) {
    padding: 0 $containerPadding70;
  }

  .navigation {
    min-width: 180px;

    @include respond($break80) {
      min-width: 268px;
    }
  }

  .main {
    width: 100%;
    padding-top: 48px;

    @include respond($break65) {
      padding-top: 0;
      padding-left: 45px;
    }

    @include respond($break80) {
      padding-left: 66px;
    }

    @include respond($break100) {
      padding-left: 78px;
    }

    &.no-results {
      @include respond($break65) {
        padding-left: 0;
      }

      @include respond($break80) {
        padding-left: 0;
      }

      @include respond($break100) {
        padding-left: 0;
      }
    }

    .pagination {
      padding: 0;
      padding-top: 60px;

      @include respond($break65) {
        padding-top: 75px;
      }

      @include respond($break110) {
        padding-top: 130px;
      }

      .text-wide {
        display: none;

        @include respond($break110) {
          display: inline;
        }
      }

      .trim {
        @include respond($break90, max) {
          display: none;
        }

        @include respond($break65, max) {
          display: inline-block;
        }

        @include respond($break60, max) {
          display: none;
        }
      }
    }
  }
}
