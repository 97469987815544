.search-overlay {
  position: absolute;
  top: 126px;
  left: 0;
  width: 100%;
  padding: 20px 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity $durationSlow $timing,
    visibility $durationSlow $timing;

  @include respond($break70, max, height) {
    top: 6.25vh;
  }

  .search-open & {
    pointer-events: inherit;
    opacity: 1;
  }

  @include respond($break70) {
    @include accentSite(background-color, 8);
    top: 0;
  }

  @include respond($break65) {
    padding: 49px 0 146px;
  }

  .container {
    .site-header & { // OD
      padding: 0 $containerPaddingResp;
      text-align: left;

      @include respond($break130) {
        padding: 0 $containerPadding100;
      }
    }
  }

  header {
    h3 {
      font-size: 23px;
      font-weight: $light;
      color: $neutralWhite;

      @include respond($break70) {
        font-size: 27px;
      }
    }
  }

  .search-input {
    position: relative;
    margin-top: 20px;

    button {
      @include buttonUnstyled;
      @include iconPost($arrowRight);
      @include whiteFocus;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 11px;
      bottom: 5px;

      box-sizing: border-box;
      padding-top: 12px;
      padding-left: 10px;
      font-size: 9px;
      color: $neutralWhite;

    }

    input {
      width: 100%;
      padding-right: 43px;
      padding-bottom: 0.519em;
      padding-left: 0;
      font-size: 23px;
      font-weight: $light;
      line-height: 100%;
      color: $neutralWhite;
      background: transparent;
      border: 0;
      border-bottom: 1px solid $neutralWhite;
      border-radius: 0;
      outline: 0;
      appearance: none;

      @include respond($break70) {
        padding-right: 57px;
      }

      &::placeholder {
        font-size: 12px;
        color: $neutralWhite;
        opacity: 0.5;

        @include respond($break70) {
          font-size: 23px;
        }
      }

      @include respond($break70) {
        font-size: 27px;
      }
    }
  }

  .button-close {
    @include iconPre($xCircle);
    @include buttonUnstyled;
    @include whiteFocus('::before');
    width: 100%;
    padding: 0;
    margin-top: 44px;
    font-size: 56px;
    line-height: 1;
    color: $neutralWhite;
    text-align: center;

    @include respond($break70) {
      position: absolute;
      top: 35px;
      right: $containerPaddingResp;
      width: auto;
      margin: 0;
    }

    @include respond($break130) {
      right: 35px;
    }
  }
}
