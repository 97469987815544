.privacy {
  h1.heading-secondary {
    @include headingSecondary;
    font-weight: $regular;
  }

  p + ol {
    margin-block: 0px;
  }

  .list-header-primary {
    padding-inline: 0;
    padding-block-start: min(2.5rem, 6.66vw);
    padding-block-end: min(1.625rem, 4.3vw);
  }

  h2.heading-secondary {
    display: block;
    padding-block-end: 0;
  }

  ul {
    padding-left: 0.4rem;

    li {
      padding-left: 0.625rem;
    }

    li::marker {
      @include accentSite(color);
      font-size: 135%;
      content: "•";
    }
  }

  table {
    margin-block: min(1.625rem, 4.3vw);
    table-layout: fixed;
    inline-size: 100%;
    min-inline-size: 440px;
    border-collapse: collapse;
  }

  th {
    @include accentSite(color);
    position: sticky;
  }

  th,
  td {
    padding: 20px;
    text-align: left;
  }

  td {
    vertical-align: top;
  }
}
