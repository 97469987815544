.artwork-header {
  @include containerBase;
  display: block;
  padding: 10px $containerPaddingResp 36px;

  @include respond($break65) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px $containerPaddingResp 20px;
  }

  @include respond($break90) {
    padding-bottom: 66px;
  }

  @include respond($break130) {
    padding-right: $containerPadding90;
    padding-left: $containerPadding90;
  }

  .caption {
    margin: 0; // OD
    margin-bottom: 0.7em;
    font-size: 22px;
    font-weight: $light;
    line-height: 1.333em;
    color: $neutral80;

    @include respond($break70) {
      font-size: 24px;
    }

    @include respond($break90) {
      font-size: 27px;
    }

    &.secondary {
      margin-bottom: 0;
      letter-spacing: 0.037em;

      @include respond($break90) {
        font-size: 24px;
      }
    }

    span {
      @include respond($break50) {
        display: block;
      }
    }
  }

  .subtitle {
    margin-top: 0.375em;
    font-size: 14px;

    @include respond($break65) {
      max-width: 340px;
      margin-top: 1em;
      font-size: 16px;
    }
  }

  .summary {
    display: flex;
    align-items: center;
    font-size: 16px;

    @include respond($break70) {
      font-size: 18px;
    }

    .summary {
      margin-right: 35px;
    }
  }
}
