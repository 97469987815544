/*!
 * froala_editor v2.6.0 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2017 Froala Labs
 */

.rte-content, .content-default {
  img {
    position: relative;
    max-width: 100%;
    height: auto;
  }
  img.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  img.fr-dib.fr-fil {
    margin-left: 0;
  }
  img.fr-dib.fr-fir {
    margin-right: 0;
  }
  img.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  img.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  img.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  img.fr-rounded {
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  img.fr-bordered {
    border: solid 10px #CCC;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .fr-video {
    text-align: center;
    position: relative;
  }
  .fr-video > * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 100%;
    border: none;
  }
  .fr-video.fr-dvb {
    display: block;
    clear: both;
  }
  .fr-video.fr-dvb.fr-fvl {
    text-align: left;
  }
  .fr-video.fr-dvb.fr-fvr {
    text-align: right;
  }
  .fr-video.fr-dvi {
    display: inline-block;
  }
  .fr-video.fr-dvi.fr-fvl {
    float: left;
  }
  .fr-video.fr-dvi.fr-fvr {
    float: right;
  }
  a.fr-strong {
    font-weight: 700;
  }
  a.fr-green {
    color: green;
  }
  button.fr-rounded,
  input.fr-rounded,
  textarea.fr-rounded {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  button.fr-large,
  input.fr-large,
  textarea.fr-large {
    font-size: 24px;
  }
  /**
   * Image style.
   */
  a.fr-view.fr-strong {
    font-weight: 700;
  }
  a.fr-view.fr-green {
    color: green;
  }
  /**
   * Link style.
   */
  img{
    position: relative;
    max-width: 100%;
  }
  img.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  img.fr-view.fr-dib.fr-fil {
    margin-left: 0;
  }
  img.fr-view.fr-dib.fr-fir {
    margin-right: 0;
  }
  img.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  img.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  img.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  img.fr-view.fr-rounded {
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  img.fr-view.fr-bordered {
    border: solid 10px #CCC;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}