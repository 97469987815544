// Temporary test location
// Should be deleted or moved to new partial if used
.horizontal-nav-reveal {
  position: relative;
  display: none;
  width: 100%;
  padding-bottom: 15px;
  pointer-events: none;

  @include respond($break60) {
    display: block;
  }

  .list-header-primary + & {
    @include respond($break130) {
      top: -36px;
      height: 123px;
    }
  }

  .anchor {
    padding-bottom: 100px;
    margin-top: -100px;
    pointer-events: none;
  }

  ul {
    @include listUnstyled;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;
    background-color: $neutralOffWhite;

    @include respond($break70) {
      padding-bottom: 0;
      background-color: transparent;
    }
  }

  li {
    padding: 0px 17px;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.active a {
      color: $neutralBlack;
    }
  }

  a {
    @include labelPrimary;
    @include accentFocus;
    position: relative;
    display: block;
    padding-bottom: 9px;
    color: $neutral50;
    pointer-events: auto;
    opacity: 0.2;
    transition: color $duration $timing;
    transition: opacity $duration $timing, color $duration $timing;

    &.active {
      color: $neutralBlack;
      opacity: 1;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 7px;
        content: '';
        background-color: $neutral15;
      }
    }

    &:hover {
      color: $neutralBlack;
      opacity: 1;
    }
  }
}
