// Static list on wide screens
// Dropdown on narrower screens
.content-page-nav-static {
  // Provisional, depends on how hero image
  // padding can be handled
  margin-top: -30px;
  margin-bottom: 28px;

  @include respond($break60) {
    margin-top: -18px;
    margin-bottom: 48px;
  }

  @include respond($break110) {
    margin: 0;
  }

  .container {
    @include containerBase;
    padding: 0 $containerPaddingResp 0;


    @include respond($break110) {
      height: 0;
      padding: 0 $containerPadding20 0;
    }

    @include respond($break130) {
      padding: 0 0 0 $containerPadding90;
    }
  }

  .menu {
    position: relative;
    display: block;
    min-width: 260px;
    background-color: $neutral15;

    @include respond($break40) {
      display: inline-block;
    }

    @include respond($break110) {
      float: left;
      min-width: 0;
      padding-left: 34px;
      background: transparent;
    }

    &.open {
      ul {
        top: 100%;
        visibility: visible;
        opacity: 1;
        transition: opacity $duration $timing,
          visibility $duration $timing,
          top $duration $timing;
      }

      header::before {
        transform: rotate(180deg);
        transform-origin: 37% 50%;
      }
    }

    header {
      @include iconPre($caretDown);
      position: relative;
      // Local z-index
      z-index: 5;
      padding: 0.417em 10px 0.5em;
      font-size: 12px;
      font-weight: $bold;
      text-transform: uppercase;
      letter-spacing: 0.323em;
      cursor: pointer;

      @include respond($break110) {
        position: absolute;
        bottom: 47px;
        left: -4.75em;
        display: block;
        padding: 0;
        color: $neutral50;
        cursor: inherit;
        transform: rotate(-90deg);
      };

      &::before {
        position: absolute;
        top: 50%;
        right: 11px;
        display: block;
        margin-top: -4px;
        font-size: 7px;
        pointer-events: none;
        transition: transform $duration $timing;

        @include respond($break110) {
          display: none;
        }
      }

      &::after {
        display: none;
        width: 24px;
        margin-left: -2px;
        content: '';
        border-bottom: 1px solid $neutral50;

        @include respond($break110) {
          display: inline-block;
        }
      }
    }

    ul {
      @include listUnstyled;
      position: absolute;
      top: calc(100% - 12px);
      left: 0;
      // Local z-index
      z-index: 0;
      width: 100%;
      padding: 3px 0 8px;
      visibility: hidden;
      background-color: $neutral20;
      opacity: 0;
      transition: top 1s $timing $duration,
        opacity $durationSlow $timing,
        visibility $duration $timing;

      @include respond($break110) {
        position: static;
        display: block;
        max-width: 170px;
        padding: 0;
        text-align: right;
        visibility: visible;
        background-color: transparent;
        opacity: 1;
      }

      @include respond($break120) {
        max-width: 200px;
      }

      li {
        + li {
          @include respond($break110) {
            margin-top: 0.4em;
          }
        }
      }

      li a {
        @include accentFocus;
        display: block;
        padding: 0.5em 10px;
        font-size: 12px;
        color: $neutral50;
        letter-spacing: 0.054em;

        @include respond($break110) {
          padding: 0;
        }
      }
    }
  }
}
