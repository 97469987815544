// Base mixins
// ---------

// Clearfix
// $mode accepts 'light' or 'heavy'
// 'light' is sufficient for clearing floats, while 'heavy' is required to
// prevent margin collapsing
// --------------------------
@mixin clearfix($mode: light) {
  @if $mode == heavy {
    &::before,
    &::after {
      display: table;
      content: ' ';
    }

    &::after {
      clear: both;
    }
  } @else {
    &::after {
      display: block;
      clear: both;
      content: ' ';
    }
  }
}

.clearfix {
  @include clearfix;
}

// Kill ye FOUT
// Depends on Typekit's webfontloader adding the .wf-active class...
// --------------------------------------------------------
@mixin killFout {
  visibility: hidden;

  .wf-active &, // Show when fonts become active
  .no-js &, // Show if no javascript
  // scss-lint:disable SelectorFormat
  .mceContentBody & { // Show if this is the RTE ;)
    visibility: visible;
  }
}

@mixin thinGlyphs {
  // Ignore spelling and prefixes for pre-standards parameter
  // scss-lint:disable PropertySpelling
  font-smooth: always;
  // scss-lint:disable VendorPrefix
  -webkit-font-smoothing: antialiased;
  // scss-lint:disable VendorPrefix
  -moz-osx-font-smoothing: grayscale;
}

// Lists
// --------------------------------------------------------
// Unstyled List
// --------------------------
@mixin listUnstyled {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

// Horizontal List
// --------------------------
@mixin listHorizontal {
  @include listUnstyled;

  li {
    display: inline-block;
  }
}

// Links, Buttons
// --------------------------------------------------------
@mixin buttonUnstyled {
  background: transparent;
  border: 0;
  appearance: none;
  // Turn off outline for click events
  &:not(:focus-visible) {
    outline: none;
  }
}

@mixin accentFocus($pseudo-class: '') {
  @if $pseudo-class {
    // Remove default outline
    outline: none;
  }

  &:focus-visible#{$pseudo-class} {
    outline-width: $focusOutlineWidth;
    outline-style: solid;
    @include accentSite(outline-color);
  }
}

@mixin whiteFocus($pseudo-class: '') {
  @if $pseudo-class {
    // Remove default outline
    outline: none;
  }

  &:focus-visible#{$pseudo-class} {
    outline: $focusOutlineWidth solid white;
  }
}

// To be applied on <select>
@mixin selectUnstyled($color) {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
  // Turn off outline for click events
  &:not(:focus-visible) {
    outline: none;
  }

  &:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color;
  }

  &::-ms-expand {
    display: none;
  }
}

// Prevents user from accidentally selecting text
// on click, helpful for labels
@mixin noSelect {
  // Ignore vendor prefixing as this is a pre-standards parameter
  user-select: none;
}
