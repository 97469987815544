// Main site navigation and hamburger
.site-nav {
  @mixin navPrimary {
    position: relative;
    font-size: 17px;
    font-weight: $bold;
    color: $neutralWhite;
    text-transform: uppercase;
    letter-spacing: 0.092em;

    @include respond($break40) {
      font-size: 19px;
    }

    @include respond($break70) {
      font-size: 12px;
      color: $neutral50;
    }

    @include respond($break80) {
      font-size: 13px;
    }

    .subtitle {
      position: absolute;
      top: 100%;
      left: 0;
      display: none;
      width: 100%;
      padding-top: 10px;
      font-size: 13px;
      font-style: oblique;
      font-weight: $regular; // OD
      color: $neutral50;
      text-align: center;
      text-transform: none; // OD
    }
  }

  @include respond($break70) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &.hidden {
    display: none;
  }

  .menu-overlay {
    @include accentSite(background-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0; // Local z-index
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity $durationSlow $timing,
      visibility $durationSlow $timing;

    .hamburger-open &, .search-open & {
      visibility: visible;
      opacity: 1;

      @include respond($break70) {
        display: none;
      }
    }
  }

  > ul {
    @include listUnstyled;
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    padding: 94px $containerPaddingResp 80px;
    overflow: scroll;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: none;

    @include respond($break70) {
      position: static;
      display: inline-block;
      width: auto;
      padding: 0;
      overflow: visible;
      text-align: inherit;
      visibility: visible;
      opacity: 1;

      transition: opacity $durationSlow $timing,
        visibility $durationSlow $timing;
    }

    .hamburger-open & {
      visibility: visible;
      opacity: 1;

      @include respond($break70) {
        display: inline-block;
      }
    }

    li {
      display: block;

      @include respond($break70) {
        display: inline-block;
        vertical-align: bottom;
      }

      &.active > a, &.active .dropdown-toggle {
        @include respond($break70) {
          color: $neutralBlack;
          border-bottom: 1px solid $neutralBlack;

          .subtitle {
            display: block;
          }
        }
      }

      &.desktop-only {
        display: none;

        @include respond($break70) {
          display: inline-block;
        }
      }

      &.mobile-only {
        display: block;

        @include respond($break70) {
          display: none;
        }
      }

      + li {
        margin-top: 10px;

        @include respond($break70) {
          margin-top: 0;
          margin-left: 8px;
        }
      }
    }

    .caret {
      @include iconPost($caretDown);
      display: flex;
      align-items: center;

      &::after {
        margin-left: 7px;
        font-size: 7px;
      }
    }

    .group {
      > a {
        @include iconPost($caretDown);
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          display: block;
          margin: -2px -18px 0 10px;
          font-size: 7px;
          transition: transform $duration $timing;
          transform: rotate(0deg);
        }

        &.open::after {
          transform: rotate(180deg);
        }
      }

      ul {
        @include listUnstyled;
        max-height: 0;
        overflow: hidden;
        transition: max-height $durationSlow $timing,
          padding $durationSlow $timing;

        a {
          text-transform: none; // OD
        }
      }

      .open + ul,
      &.focus-within ul,
      &:focus-within ul {
        max-height: 500px;
        padding-top: 8px;
      }
    }

    .hamburger-close {
      margin-top: 11px;

      @include respond($break70) {
        display: none;
      }

      button {
        @include whiteFocus(':before');
      }
    }

    .icon-x-circle {
      @include buttonUnstyled;
      @include iconPre($xCircle);
      font-size: 56px;
      color: $neutralWhite;
    }

    a, .dropdown-toggle {
      @include navPrimary;
      @include accentFocus;
      display: block;
      padding: 0 0 3px;
      cursor: pointer;
      border-bottom: 1px solid transparent;

      @include respond($break40) {
        padding: 0 0.846em 3px;
      }

      &:hover {
        @include respond($break70) {
          color: $neutralBlack;
        }
      }

      .hamburger-open & {
        @include whiteFocus;
      }
    }
  }

  .search {
    @include buttonUnstyled;
    @include iconPre($magnify);
    @include accentFocus;
    position: relative;
    z-index: 5;
    padding: 0;
    margin-left: 8px;
    font-size: 16px;
    line-height: 1;
    color: $neutral50;
    vertical-align: middle;
    transition: color $durationFast $timing;

    @include respond($break80) {
      margin-left: 19px;
    }

    .hamburger-open &, .search-open & {
      color: $neutralWhite;

      @include respond($break70) {
        padding-top: 0;
        color: $neutral50;
      }
    }

    &:hover, &.active {
      color: $neutralBlack;
    }
  }

  .hamburger {
    @include buttonUnstyled;
    @include iconPre($hamburger);
    position: relative;
    z-index: 5;
    display: inline-block;
    padding: 0;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1;
    color: $neutral50;
    vertical-align: middle;
    transition: color $durationFast $timing;

    @include respond($break70) {
      display: none;
    }

    &::before {
      .hamburger-open & {
        @include accentSite(background-color);
      }
    }

    .hamburger-open &, .search-open & {
      color: $neutralWhite;
    }

    &:hover, &.active, &:focus-visible {
      color: $neutralBlack;
      outline: none;
    }
  }

  // Has its own partial
  .search-overlay {
    z-index: 5;
  }
}
