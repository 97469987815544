// Component Typography and Prototypes
// -----------------------------------

// Template typography mixins/prototypes and utility classes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

@mixin headingPrimary {
  @include accentSite(color, 11);
  font-size: 26px;
  font-weight: $regular;
  line-height: 1.031;
  letter-spacing: 0.028em;

  @include respond($break70) {
    font-size: 29px;
  }

  @include respond($break90) {
    font-size: 32px;
  }
}

@mixin headingSecondary {
  font-size: 22px;
  font-weight: $light;
  line-height: 1.333em;

  @include respond($break50) {
    margin-bottom: 0;
    font-size: 24px;
  }

  @include respond($break60) {
    font-size: 27px;
  }
}

@mixin labelPrimary {
  font-size: 13px;
  font-weight: $bold;
  text-transform: uppercase;
  letter-spacing: 0.115em;
}

@mixin labelSecondary {
  // Spaced text used on link buttons, buttons, dropdowns
  font-size: 10px;
  font-weight: $bold;
  text-transform: uppercase;
  letter-spacing: 0.323em;

  @include respond($break70) {
    font-size: 12px;
  }

  @include respond($break100) {
    font-size: 13px;
  }
}

// Used as mini captions on artworks
@mixin captionPrimary {
  font-size: 11px;
  font-style: oblique;
  font-weight: $regular;
  line-height: 1.273;
  color: $neutral50;
  letter-spacing: 0.065em;
}

.caption-primary {
  @include captionPrimary;
  margin-top: 10px;
}

// Reused through exhibition and search
@mixin exhibitionListing {
  h3 {
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: $regular;
    line-height: 1.25;

    @include respond($break90) {
      font-size: 18px;
    }

    .title {
      font-style: italic;
      line-height: 1.4;
    }
  }

  .dates {
    display: block;
    font-size: 13px;
    font-weight: $bold;
    letter-spacing: 0.046em;
  }

  .category {
    font-size: 12px;
    font-weight: $medium;
    color: $neutral50;
  }
}
