.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $neutralOffWhite;

  &.opaque {
    @include respond($break70) {
      border-bottom: 47px solid $neutralOffWhite;
    }
  }

  &.hamburger-open .logo-primary, &.search-open .logo-primary {
    visibility: visible;
    opacity: 1;

    polygon, path, rect {
      fill: $neutralWhite;

      @include respond($break70) {
        @include accentSite(fill);
      }
    }
  }

  .container {
    padding: 8px $containerPaddingResp 8px;
    text-align: right;

    @include respond($break70) {
      padding: 18px $containerPaddingResp 10px;
    }

    @include respond($break130) {
      padding: 26px 48px 13px $containerPadding90;
    }
  }
}

.site-header-bump {
  height: $headerBumpNarrow;

  @include respond($break70) {
    height: $headerBumpMax;
  }
}
