.breadcrumb-primary {
  @include containerBase;
  display: block;
  padding: 0 $containerPaddingResp 6px;

  @include respond($break40) {
    padding-bottom: 18px;
  }

  @include respond($break70) {
    padding-top: 0;
  }

  @include respond($break100) {
    padding-bottom: 23px;
  }

  @include respond($break130) {
    padding: 24px $containerPadding90 28px;
  }

  > a {
    @include iconPre($arrowLeft);
    position: relative;
    padding-left: 31px;
    font-size: 12px;
    color: $neutral50;
    letter-spacing: 0.054em;

    &:hover::before {
      left: -8px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      font-size: 9px;
      color: $neutralBlack;
      transition: left $duration $timing;
    }
  }
}
