.site-footer {
  // Allow banner link to expand out the sides
  overflow: hidden;

  .colophon-container {
    @include containerBase;
    padding: 50px $containerPaddingResp 10vh;

    @include respond($break130) {
      padding: 74px 134px 137px;
    }
  }

  .colophon {
    font-size: 13px;
    font-weight: $light;
    letter-spacing: 0.03em;

    p {
      margin: 0;

      + p {
        margin-top: 2px;
      }
    }

    a {
      @include accentFocus;
      color: $neutralBlack;
      text-decoration: underline;
      transform: color $duration $timing;

      &:hover {
        color: $neutral50;
      }
    }

    &.right {
      @include respond($break60) {
        width: 46%;
        margin: 0 0 0 auto;
        text-align: right;
      }
    }
  }
}
