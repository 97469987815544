.two-column-ragged {
  @include containerBase;
  max-width: $containerWidthMax;
  padding-top: 0;
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;

  @include respond($break60) {
    padding-top: 48px;
  }

  @include respond($break80) {
    padding-top: 50px;
  }


  @include respond($break130) {
    padding-right: $containerPadding100;
    padding-left: $containerPadding100;
  }

  .column-container {
    display: none;

    @include respond($break60) {
      display: flex;
      justify-content: center;
      margin-left: -28px;
    }

    @include respond($break70) {
      margin-left: -35px;
    }

    @include respond($break90) {
      margin-left: -50px;
    }

    + .column-container {
      margin-top: 42px;

      @include respond($break90) {
        margin-top: 91px;
      }
    }
  }

  .single-container {
    @include respond($break60) {
      display: none;
    }
  }

  .col {
    @include respond($break60) {
      min-width: 50%;
      padding-left: 28px;
    }

    @include respond($break70) {
      padding-left: 35px;
    }

    @include respond($break90) {
      padding-left: 50px;
    }

    &.left {
      text-align: right;
    }

    &.right {
      text-align: left;
    }
  }

  .col-item {
    position: relative;

    .left & {
      text-align: right;
    }

    + .col-item {
      margin-top: 24px;

      @include respond($break60) {
        margin-top: 42px;
      }

      @include respond($break90) {
        margin-top: 62px;
      }
    }
  }

  .escape {
    margin-right: -$containerPaddingResp;
    margin-left: -$containerPaddingResp;

    @include respond($break60) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .show-60 {
    display: none;
    @include respond($break60) {
      display: inline-block;
    }
  }

  .hide-60 {
    @include respond($break60) {
      display: none;
    }
  }

  .logo-bump {
    @include respond($break60) {
      margin-top: 158px;
    }

    @include respond($break70) {
      margin-top: 124px;
    }

    @include respond($break80) {
      margin-top: 225px;
    }
  }
}
