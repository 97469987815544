.content-quote {
  @include iconPre($quoteLeft);
  position: relative;
  display: block;
  padding: 55px $containerPaddingResp 42px;
  color: transparentize($neutralBlack, 0.3);

  @include respond($break60) {
    padding: 82px 5vw 72px;
  }

  @include respond($break130) {
    padding: 82px 80px 72px;
  }

  &:not([class*='bg-accent-']) {
    // Default color
    background-color: nth($accentSecondary, 5);
  }

  .content-block > & {
    // Single quotes have a max width
    @include respond($break70) {
      max-width: 550px;
      margin: 0 auto;
    }
  }

  + .content-quote {
    margin-top: 40px;
  }

  .rte-content {
    font-size: 16px;
    font-weight: $light;
    letter-spacing: 0.028em;

    @include respond($break70) {
      font-size: 18px;
    }
  }

  &::before {
    position: absolute;
    top: 29px;
    left: $containerPaddingResp;
    font-size: 10px;

    @include respond($break60) {
      top: 43px;
      left: 44px;
      font-size: 11px;
    }
  }
}
