.art-banner-link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  margin-top: 54px;
  color: $neutralBlack;
  transition: opacity $durationSlow $timing;
  transform: scale(0.5);

  /* stylelint-disable scss/media-feature-value-dollar-variable */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    position: relative;
    left: -50%;
    width: 200%;
  }
  /* stylelint-enable */

  @include respond($break60) {
    width: 130%;
    margin: 50px -15% 0;
    transform: scale(1);
  }

  /* stylelint-disable scss/media-feature-value-dollar-variable */
  @media screen and (-ms-high-contrast: active) and (min-width: $break60), screen and (-ms-high-contrast: none) and (min-width: $break60) {
    left: -65%;
    width: 130%;
    // overflow: hidden;
  }
  /* stylelint-enable */

  @include respond($break90) {
    width: 110%;
    margin: 50px -5% 0;
  }

  /* stylelint-disable scss/media-feature-value-dollar-variable */
  @media screen and (-ms-high-contrast: active) and (min-width: $break90), screen and (-ms-high-contrast: none) and (min-width: $break90) {
    left: -55%;
    width: 110%;
    // overflow: hidden;
  }
  /* stylelint-enable */

  @include respond($containerWidthFooter) {
    max-width: $containerWidthFooter;
    margin: 50px auto 0;
  }

  &:hover {
    opacity: 0.5;
  }

  figure {
    padding-left: 2px;

    @include respond($break60) {
      padding-left: 5px;
    }

    @include respond($break70) {
      padding-left: 10px;
    }
  }

  .text {
    @include labelSecondary;
    // Could be abstracted, but so far only used in 2 places
    padding: 0 $containerPaddingResp 18px;

    @include respond($break60) {
      padding: 0;
      padding-top: 2vw;
    }

    @include respond($containerWidthFooter) {
      padding-top: 30px;
    }
  }
}
