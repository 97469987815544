// Donezo Generated Icon File:
// File will be overwritten if regenerated

// Icon Variables
// --------------------------------------------------------
$arrowLeft: '\e900';
$arrowRight: '\e901';
$arrowShortLeft: '\e902';
$arrowShortRight: '\e903';
$caretDown: '\e904';
$hamburgerThin: '\e905';
$hamburger: '\e906';
$magnify: '\e907';
$plus: '\e908';
$quoteLeft: '\e909';
$triangleRight: '\e90a';
$xCircle: '\e90b';
$x: '\e90c';


@font-face {
  font-family: 'hockney-icons';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/hockney-icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/hockney-icons.woff') format('woff'),
    url('../fonts/hockney-icons.ttf') format('truetype'),
    url('../fonts/hockney-icons.svg') format('svg');
}


@mixin fontIcon {
  font-family: 'hockney-icons', sans-serif;
}

@mixin protoIcon {
  @include fontIcon;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: baseline;
  speak: none;
}

@mixin iconPre($icon) {
  speak: none;

  &::before {
    @include protoIcon;
    content: $icon;
  }
}

@mixin iconPost($icon) {
  speak: none;

  &::after {
    @include protoIcon;
    content: $icon;
  }
}
