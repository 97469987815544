// Component Structure
// -------------------

// Structure-only mixins (and maybe utility classes), with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context can use em units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary
// for scaling

// Utility
// --------------------------------------------------------
.rel {
  position: relative;
}

.hide-overflow-y {
  overflow-y: hidden;
}

.hide-overflow-x {
  overflow-x: hidden;
}

// Show/hide utility classes
.show-70 {
  display: none;

  @include respond($break70) {
    display: inherit;
  }
}

// Layout
// --------------------------------------------------------
@mixin containerBase {
  // Max width container with no padding
  // Add additional padding mixins below!
  max-width: $containerWidthMax;
  margin: 0 auto;
}

.container {
  @include containerBase;
}

// Exists inside another container
.container-full {
  max-width: 1150px;
  margin: 0 auto;
}

@mixin containerFocus {
  width: calc(600px + #{$containerPaddingResp * 2});
  max-width: 100%;
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;

  @include respond($break70) {
    // Remove padding once wider than 600px
    width: $containerWidthFocus;
    padding-right: 0;
    padding-left: 0;
  }
}

.container-focus {
  @include containerFocus;
}

.link-anchor {
  display: block;
  height: $headerBumpNarrow;
  margin-top: -$headerBumpNarrow;

  @include respond($break70) {
    height: $headerBumpMax;
    margin-top: -$headerBumpMax;
  }
}

.hero-bump {
  height: 33px;

  @include respond($break60) {
    height: 25px;
  }

  @include respond($break110) {
    height: 100px;
  }
}
