.index-nav {
  ul {
    @include listUnstyled;

    li {
      border-bottom: 1px solid $neutral20;

      + li a {
        padding-top: 0.7em;

        @include respond($break50) {
          padding-top: 1.923em;
        }
      }
    }

    a {
      @include accentFocus;
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.45em;
      font-size: 12px;
      font-weight: $bold;
      line-height: 1.35;
      color: $neutralBlack;
      text-transform: uppercase;
      letter-spacing: 0.115em;

      @include respond($break50) {
        padding-bottom: 0.85em;
        font-size: 13px;
      }

      span {
        transition: color $duration $timing;
      }

      &:hover {
        span:first-child {
          @include accentSite(color);
        }
      }
    }
  }
}
