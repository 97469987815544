.content-milestones {
  @include containerBase;
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;

  .component-content {
    max-width: 600px;
    margin: 0 auto;
    font-size: 16px;

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0.563em;
      font-size: 16px;
      font-weight: $bold;
    }

    ul {
      @include listUnstyled;

      + h1, + h2, + h3, + h4, + h5, + h6 {
        margin-top: 1.875em;
      }
    }
  }
}