.filters-list {
  display: flex;
  flex-direction: column;

  @include respond($break70) {
    display: grid;
    grid-template-rows: 25fr 25fr 50fr;
    grid-template-columns: 25fr 25fr 25fr 25fr;
    grid-column-gap: 5vw;
  }

  @include respond($break130) {
    grid-column-gap: 70px;
  }

  .group {
    max-height: 40px;
    overflow: hidden;
    transition: max-height $durationSlow $timing;

    @include respond($break70) {
      max-height: none;
      padding-bottom: 15px;
      overflow: visible;
    }

    &.open {
      max-height: 600px;
      @include respond($break70) {
        max-height: none;
      }

      .all {
        visibility: visible;
        opacity: 1;
      }

      .caret {
        transform: rotate(180deg);
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $neutral20;

      @include respond($break70) {
        padding-bottom: 4px;
        margin-bottom: 8px;
        border-color: $neutralBlack;
      }

      h5 {
        @include labelPrimary;
        width: 100%;
        padding: 13px 0;
        font-size: 11px;
        cursor: pointer;

        @include respond($break70) {
          padding: 0;
          font-size: 14px;
          cursor: default;
        }
      }

      .utility {
        display: flex;
        align-items: center;
      }

      .all {
        @include buttonUnstyled;
        @include accentFocus;
        padding: 0;
        margin-right: 14px;
        font-size: 12px;
        font-weight: $bold;
        color: $neutral50;
        letter-spacing: 0.054em;
        visibility: hidden;
        opacity: 0;
        transition: opacity $duration $timing,
          visibility $duration $timing;

        @include respond($break70) {
          margin-right: 0;
          visibility: visible;
          opacity: 1;
        }
      }

      .caret {
        @include iconPost($caretDown);
        display: block;
        padding-right: 4px;
        font-size: 7px;
        cursor: pointer;
        transition: transform $duration $timing;
        transform: rotate(0deg);
        transform-origin: 33% 30%;

        @include respond($break70) {
          display: none;
        }
      }
    }

    &.painting {
      grid-row-start: 1;
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: span 2;
      order: 0;

      @include respond($break70) {
        order: unset;
      }

      @include respond($break90) {
        grid-column-end: span 1;
      }
    }

    &.watercolor {
      grid-row-start: 2;
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: span 2;
      order: 1;

      @include respond($break70) {
        order: unset;
      }

      @include respond($break90) {
        grid-row-start: 2;
        grid-column-end: span 1;
      }
    }

    &.drawing {
      grid-row-start: 1;
      grid-row-end: span 2;
      grid-column-end: span 2;
      order: 2;

      @include respond($break70) {
        order: unset;
      }

      @include respond($break90) {
        grid-row-end: span 2;
        grid-column-end: span 1;
      }

      ul {
        columns: unset;

        @include respond($break70) {
          columns: 2;
          column-gap: 5.109vw;
        }

        @include respond($break90) {
          columns: unset;
        }
      }
    }

    &.photography {
      grid-row-start: 3;
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: span 2;
      order: 3;

      @include respond($break70) {
        order: unset;
      }

      @include respond($break90) {
        grid-row-start: 1;
        grid-row-end: span 2;
        grid-column-start: 3;
        grid-column-end: span 1;
      }
    }

    &.print {
      grid-row-start: 3;
      grid-row-end: span 1;
      grid-column-start: 3;
      grid-column-end: span 2;
      order: 4;

      @include respond($break70) {
        order: unset;
      }

      @include respond($break90) {
        grid-row-start: 1;
        grid-row-end: span 3;
        grid-column-start: 4;
        grid-column-end: span 1;
      }

      ul {
        columns: unset;

        @include respond($break70) {
          columns: 2;
          column-gap: 5.109vw;
        }

        @include respond($break90) {
          columns: unset;
        }
      }
    }

    &.theater-work {
      grid-column-start: 3;
      grid-column-end: span 2;
      order: 5;

      @include respond($break90) {
        grid-column-end: span 1;
      }
    }

    &.other {
      grid-row-start: 4;
      grid-column-start: 1;
      grid-column-end: span 2;
      order: 6;

      @include respond($break90) {
        grid-row-start: 3;
      }

      ul {
        columns: unset;

        @include respond($break70) {
          columns: 2;
          column-gap: 5.109vw;
        }

        @include respond($break130) {
          column-gap: 70px;
        }
      }
    }
  }

  // <ul>
  .filters {
    @include listUnstyled;

    li {
      position: relative;

      &.active {
        font-weight: $bold;

        @include respond($break70) {
          font-weight: $regular;
        }

        &::before {
          opacity: 1;
        }
      }

      &::before {
        @include accentSite(background-color);
        position: absolute;
        top: 50%;
        right: 7px;
        display: block;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        content: '';
        border-radius: 3px;
        opacity: 0;
        transition: opacity $duration $timing;

        @include respond($break70) {
          right: auto;
          left: -17px;
        }
      }
    }

    button {
      @include buttonUnstyled;
      @include accentFocus;
      display: block;
      width: 100%;
      padding: 4px 0;
      font-size: 14px;
      text-align: left;
      letter-spacing: 0.054em;
    }
  }
}
