.content-page-nav-fixed {
  // Local variables
  $letterSpacing: 0.059em;
  margin-top: 30px;


  @include respond($break70) {
    position: fixed;
    width: 31vw;
    padding: 3.5vw 4.34vw 5.2vw 4.85vw;
    margin-top: 0;
  }

  @include respond($break110) {
    width: 360px;
    padding: 61px 68px 77px 71px;
  }

  header {
    // Only shown on mobile as a dropdown trigger
    @include iconPost($caretDown);
    position: relative;
    padding: 0.889em 45px 0.778em;
    font-size: 18px;
    color: $neutralWhite;
    text-align: center;
    letter-spacing: $letterSpacing;

    @include respond($break70) {
      display: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 45px;
      margin-top: -3.5px;
      font-size: 7px;
    }
  }


  ul {
    @include listUnstyled;
    position: relative;
    display: block;
    max-height: 62px;
    overflow: hidden;
    transition: max-height $duration $timingBounceBack;

    .open & {
      max-height: 200px;
    }

    @include respond($break70) {
      max-height: none;
      overflow: visible;
    }

    li {
      position: relative;

      &.active a, &.active::after {
        opacity: 1;
      }

      &:first-child a {
        @include respond($break70, max) {
          padding-top: 0.944em;
        }
      }

      &:last-child a {
        @include respond($break70, max) {
          padding-bottom: 1.667em;
        }
      }

      &.bare::after {
        display: none;
      }

      &::after {
        display: none;
        width: 100%;
        height: 1px;
        content: '';
        background: $neutralBlack;
        opacity: 0.2;
        transition: $duration $timing opacity;

        @include respond($break70) {
          display: block;
        }
      }
    }

    a {
      @include whiteFocus;
      position: relative;
      display: block;
      padding: 0 0 1.05em;
      font-size: 18px;
      color: $neutralBlack;
      text-align: center;
      letter-spacing: $letterSpacing;
      opacity: 1;
      transition: $duration $timing opacity;

      @include respond($break60) {
        font-size: 20px;
      }

      @include respond($break70) {
        padding: 0.727em 0 0.5em;
        text-align: left;
        opacity: 0.6;
      }

      @include respond($break100) {
        font-size: 22px;
      }

      .caret {
        @include iconPost($caretDown);
        position: absolute;
        top: 50%;
        right: 45px;
        margin-top: -4px;
        font-size: 7px;
        transition: transform 0.8s $timing;

        .open & {
          transform: rotate(540deg);
          transform-origin: 50% 30%;
        }

        @include respond($break70) {
          display: none;
        }
      }
    }
  }
}
