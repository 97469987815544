// No results messaging
.flash-container {
  width: 100%;
  margin-bottom: 45px;

  .flash-notice {
    @include respond($break70) {
      padding: 26px 20px;
    }
  }

  &.tall {
    .flash-notice {
      // CABS
      padding: 10px 20px 40px;

      @include respond($break60) {
        padding: 63px 30px;
      }

      @include respond($break70) {
        min-height: 350px;
      }
    }
  }

  &.border {
    .flash-notice {
      @include respond($break70) {
        border: 1px solid $neutral20;
      }
    }
  }
}

.flash-notice {
  width: 100%;
  text-align: center;

  header {
    h3 {
      @include headingSecondary;
      @include respond($break80, max) {
        font-size: 25px;
      }

      @include respond($break60, max) {
        font-size: 22px;
      }
    }
  }

  .subtitle {
    display: block;
    margin-top: 1.8em;
    margin-bottom: 55px;
    font-size: 13px;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 0.115em;

    @include respond($break65) {
      margin-top: 2.615em;
    }
  }

  .keywords {
    margin-bottom: 20px;

    ul {
      @include listUnstyled;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      padding: 0 8px 16px;

      @include respond($break65) {
        padding: 0 10px 20px;
      }
    }

    a {
      display: block;
      padding: 0.6em 18px 0.8em;
      font-size: 16px;
      color: $neutralBlack;
      white-space: nowrap;
      background-color: $neutral15;
      transition: backgrond-color $duration $timing,
        color $duration $timing;

      @include respond($break65) {
        padding-right: 30px;
        padding-left: 30px;
        font-size: 18px;
      }

      &:hover {
        @include accentSite(color);
        background-color: $neutralWhite;
      }
    }
  }

  .reset-link {
    @include accentSite(color);
    display: block;
    margin-top: 25px;
    font-size: 18px;

    @include respond($break80) {
      margin-top: 32px;
    }
  }
}
