// Fonts
// -----

// Do your @font-face embedding here, in the privacy of your own partial
// Currently using trial version of GT-Walsheim in advance of official license
// License should be added here if possible once purchased, and font names/files modified to fit official downloads
@font-face {
  font-family: 'GT-Walsheim';

  font-style: normal;
  font-weight: 300;
  src: url('../fonts/GT-Walsheim-Light.eot');
  src: url('../fonts/GT-Walsheim-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Light.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Light.woff') format('woff'),
    url('../fonts/GT-Walsheim-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim';

  font-style: oblique;
  font-weight: 300;
  src: url('../fonts/GT-Walsheim-Light-Oblique.eot');
  src: url('../fonts/GT-Walsheim-Light-Oblique.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Light-Oblique.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Light-Oblique.woff') format('woff'),
    url('../fonts/GT-Walsheim-Light-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim';

  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GT-Walsheim-Regular.eot');
  src: url('../fonts/GT-Walsheim-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Regular.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Regular.woff') format('woff'),
    url('../fonts/GT-Walsheim-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim';

  font-style: oblique;
  font-weight: 400;
  src: url('../fonts/GT-Walsheim-Regular-Oblique.eot');
  src: url('../fonts/GT-Walsheim-Regular-Oblique.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Regular-Oblique.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Regular-Oblique.woff') format('woff'),
    url('../fonts/GT-Walsheim-Regular-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim';

  font-style: normal;
  font-weight: 700;
  src: url('../fonts/GT-Walsheim-Bold.eot');
  src: url('../fonts/GT-Walsheim-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Bold.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Bold.woff') format('woff'),
    url('../fonts/GT-Walsheim-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim';

  font-style: oblique;
  font-weight: 700;
  src: url('../fonts/GT-Walsheim-Bold-Oblique.eot');
  src: url('../fonts/GT-Walsheim-Bold-Oblique.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Bold-Oblique.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Bold-Oblique.woff') format('woff'),
    url('../fonts/GT-Walsheim-Bold-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'GT-Walsheim';

  font-style: normal;
  font-weight: 900;
  src: url('../fonts/GT-Walsheim-Black.eot');
  src: url('../fonts/GT-Walsheim-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GT-Walsheim-Black.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Black.woff') format('woff'),
    url('../fonts/GT-Walsheim-Black.ttf') format('truetype');
}

// Mixins
// --------------------------
@mixin fontBase {
  font-family: 'GT-Walsheim', Arial, SansSerif;
  font-weight: $regular;
}
