.video-detail {
  @include containerBase;
  max-width: $containerWidth90;
  padding: 10px $containerPaddingResp;
  margin: 0 auto;

  @include respond($break130) {
    padding: 10px $containerPadding90;
  }

  .video {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  header {
    padding-bottom: 52px;

    .title {
      padding-top: 1.263em;
      font-size: 17px;
      font-weight: $regular;
      text-align: center;

      @include respond($break50) {
        font-size: 19px;
      }
    }

    .edition {
      display: block;
      padding-top: 18px;
      font-size: 14px;
      color: $neutral50;

      + .description {
        margin-top: 2px;
      }
    }

    .description {
      margin-top: 18px;
    }
  }

  .related {
    .arrow-link {
      display: block;
      margin-bottom: 1.308em;
      font-style: italic;
    }

    .thumbnail-list {
      padding-right: 0;
      padding-left: 0;
    }

    .thumbnail-list-header {
      font-size: 13px;
    }
  }
}
