.tab-list {
  padding: 38px 0 28px;

  @include respond($break80) {
    padding: 58px 0 42px;
  }

  .nav {
    position: relative;

    ul {
      @include listUnstyled;
      display: flex;
    }

    li {
      margin-right: 24px;
      opacity: 0.4;

      @include respond($break50) {
        margin-right: 35px;
      }

      &.active {
        opacity: 1;
        transition: opacity $duration $timing;
      }
    }

    button {
      @include buttonUnstyled;
      @include labelPrimary;
      padding: 0;
      padding-bottom: 1.154em;
      white-space: nowrap;
    }
  }

  .wrapper {
    overflow: hidden;
  }

  .panes {
    @include listUnstyled;
    display: flex;
    width: 200%; // Default width
    padding-top: 5px;
    transition: transform 0.35s $timing;

    li {
      width: 100%;
    }
  }

  .list {
    @include listUnstyled;

    li + li {
      border-top: 1px solid $neutral15;
    }

    a {
      @include accentFocus;
      display: block;
      padding: 1.167em 0 0.944em;
      font-size: 16px;

      @include respond($break40) {
        font-size: 18px;
      }

      @include respond($break60) {
        font-size: 14px;
      }

      @include respond($break80) {
        font-size: 16px;
      }

      @include respond($break90) {
        font-size: 18px;
      }

      &:hover {
        .body {
          opacity: 0.5;
        }

        .more::after {
          margin-left: 10px;
          opacity: 1;
        }
      }
    }

    .body {
      color: $neutralBlack;
      text-align: left;
      transition: opacity $duration $timing;

      p {
        margin: 0;
        line-height: 1.389;
      }
    }

    .more {
      @include iconPost($arrowRight);
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $neutral50;
      letter-spacing: 0.054em;

      &::after {
        display: block;
        margin-left: 5px;
        font-size: 9px;
        color: $neutralBlack;
        opacity: 0;
        transition: opacity $durationSlow $timing,
          margin $duration $timing;
      }
    }
  }
}
