// Component Appearance
// --------------------

// Basic utility patterns
// --------------------------------------------------------
// Visually Hidden, for skip links and screen-reader only elements
@mixin screenReaderText {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

// Image replacement, for icons and things with hidden accessibility text
@mixin imageReplacement {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;

  br {
    display: none;
  }
}

.screen-reader-text {
  @include screenReaderText;
}

.skip-link {
  @include screenReaderText;
}

// Utility
// --------------------------------------------------------
// Utility mixin to add a color based on a modifier wrapper
@mixin accentSite($property, $darken: 0) {
  #{$property}: darken(nth($accentPrimary, 1), $darken);

  @for $i from 1 through length($accentPrimary) {
    .site-accent-#{$i} & {
      #{$property}: darken(nth($accentPrimary, $i), $darken);
    }
  }
}

// Utility classes for setting specific colors
@for $i from 1 through length($accentSecondary) {
  .bg-accent-#{$i} {
    background-color: nth($accentSecondary, $i);
  }
}

@for $i from 1 through length($accentSecondary) {
  .c-accent-#{$i} {
    color: nth($accentSecondary, $i);
  }
}

.no-show {
  display: none;
}

// Buttons, Links
// --------------------------------------------------------
// CABS trimLinkPrimary, trimLinkDouble
@mixin trimLink {
  @include labelSecondary;
  padding: 16px 28px 15px;
  font-size: 12px;
  color: $neutralBlack;
  text-align: center;
  border-top: 1px solid $neutralBlack;
  border-bottom: 1px solid $neutralBlack;
}

.submit-hidden {
  position: absolute;
  left: -200vw;
  opacity: 0;
}

// Default arrow link
.arrow-link {
  // Default to right
  @include iconPost($arrowRight);
  font-size: 13px;
  color: $neutralBlack;

  &:hover::after {
    transform: translateX(12px);
  }

  &::after {
    margin-left: 7px;
    font-size: 9px;
    vertical-align: middle;
    transition: transform $duration $timingBounceBack;
    transform: translateX(0px);
  }
}

.nav-marker {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 7px;
  background-color: $neutral15;
  transition: width $durationFast $timing, left $duration $timingBounceBack,
    opacity 0s linear $durationSlow;
}

// Headers
// --------------------------------------------------------
.list-header-trim {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 12px 0 13px;

  .anchor {
    padding-bottom: 75px;
    margin-top: -75px;
    pointer-events: none;
  }

  h2 {
    padding-right: 8px;
    font-size: 16px;
    font-weight: $regular;
    white-space: nowrap;
  }

  &:after {
    width: 100%;
    height: 1px;
    margin-bottom: 0.313em;
    content: "";
    background-color: $neutral20;
  }
}

// Inputs, Form Elements
// --------------------------------------------------------
@mixin inputWithTrim {
  @include accentSite(border-left-color);
  width: auto;
  // Height is relative to font-size
  height: 1.583em;
  // iOS wants 1px bottom padding for some reason
  padding: 0px 0 1px 0.292em;
  font-size: 21px;
  font-weight: $light;
  color: $neutralBlack;
  background: transparent;
  border: 0;
  border-left: 1px solid;
  border-radius: 0;
  outline: 0;
  appearance: none;

  @include respond($break60) {
    font-size: 24px;
  }
}

// CABS
.year-input {
  &.bold {
    .active::placeholder {
      color: $neutralBlack;
      opacity: 1;
    }

    .active:focus::placeholder {
      color: transparentize($neutralBlack, 0.8);
    }
  }

  &.dirty {
    .placeholder {
      opacity: 1;
    }

    .submit {
      left: 70px;
      visibility: visible;
      opacity: 1;
    }
  }

  &.arrow {
    .submit {
      display: block;
    }
  }

  &.full {
    .submit {
      left: 78px;
      color: $neutralBlack;
    }
  }

  .input-wrapper {
    position: relative;
    display: inline-block;
  }

  input,
  .ruler {
    @include inputWithTrim;
    font-weight: $black;

    &::placeholder {
      transition: color $duration $timing;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }

    &.placeholder {
      position: absolute;
      top: 0;
      left: 0;
      color: transparentize($neutralBlack, 0.8);
      pointer-events: none;
      border-color: transparent;
      opacity: 0;
    }
  }

  .ruler {
    // Hidden ruler for measuring with the shadow dom
    width: auto;
    margin-top: 1px; // NP
    pointer-events: none;
    opacity: 0;
  }

  .submit {
    @include buttonUnstyled;
    @include iconPost($arrowRight);
    position: absolute;
    top: 9px;
    left: 80px;
    display: none;
    font-size: 9px;
    color: transparentize($neutralBlack, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: color $duration $timing $durationFast, opacity $duration $timing,
      visibility $duration $timing, left $durationSlow $timing;

    @include respond($break60) {
      top: 12px;
    }
  }
}

// Centered trim button (like pagination without arrows)
.center-trim {
  @include containerBase;
  padding: 30px $containerPaddingResp 25px;
  text-align: center;

  @include respond($break70) {
    display: none;
  }

  > a {
    @include trimLink;
  }
}

.text-input-trim {
  // Similar to year input, with reset button
  position: relative;

  &.short input {
    width: 260px;
  }

  input {
    @include inputWithTrim;
    width: 330px;
    padding-right: 42px;

    &:focus ~ .trim {
      opacity: 1;
    }
  }

  &.dirty {
    .trim {
      opacity: 1;
    }
  }

  &.filled {
    .reset {
      visibility: visible;
      opacity: 1;
    }
  }

  .submit {
    @include buttonUnstyled;
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    visibility: hidden;
    border: 0;
    opacity: 0;
  }

  .reset {
    @include buttonUnstyled;
    @include accentSite(color);
    position: absolute;
    right: 0;
    bottom: 1px;
    font-size: 12px;
    letter-spacing: 0.054;
    opacity: 0;
    transition: opacity $duration $timing, visibility $duration $timing;
  }

  .trim {
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 10px;
    display: block;
    height: 1px;
    content: "";
    background-color: $neutral20;
    opacity: 0;
    transition: opacity $duration $timing;
  }
}

.input-select-secondary {
  @include iconPost($caretDown);
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -3px;
    font-size: 7px;
    pointer-events: none;
  }

  &.disabled {
    color: $neutral40;
  }

  select {
    @include selectUnstyled($neutralBlack);
    @include accentFocus;
    padding-right: 20px;
    direction: rtl;
  }

  .label {
    // Unclickable label
    position: absolute;
    left: 0;
    pointer-events: none;
  }
}
