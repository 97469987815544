.thumbnail-list {
  position: relative;
  max-width: $containerWidth90;
  padding: 10px $containerPaddingResp;
  margin: 0 auto;

  @include respond($break130) {
    padding: 10px $containerPadding90;
  }

  &.prevent-click {
    .item-link {
      pointer-events: none;
    }
  }

  .loader-bar {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .items {
    @include listUnstyled;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: -6vw;

    @include respond($break130) {
      margin-left: -110px;
    }

    .area {
      position: relative;
      width: 100%;
      padding-left: 6vw;
      margin-bottom: 60px;

      @include respond($break50) {
        width: 50%;
      }

      @include respond($break70) {
        width: 33.333%;
      }

      @include respond($break130) {
        padding-left: 110px;
      }

      &.filters {
        width: 100%;

        @include respond($break70, max) {
          margin-bottom: 36px;
        }

        @include respond($break70) {
          width: 33.333%;
        }
      }

      &.jumbo {
        width: 100%;

        @include respond($break70) {
          width: 66.666%;
        }
      }

      &.double {
        a + a {
          margin-top: 64px;
        }
      }

      a {
        @include accentFocus(" .image-wrapper");
        display: block;

        &:hover {
          .image-wrapper {
            opacity: 0.5;
          }
        }
      }

      figure {
        width: 100%;

        .image-wrapper {
          opacity: 1;
          transition: $durationSlow $timing opacity;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        .caption-secondary {
          @include labelPrimary;
          margin-top: 12px;
          font-size: 11px;
          color: $neutral50;

          .title {
            padding-bottom: 0.36em;
            // Override defaults
            font-size: 18px;
            font-weight: normal;
            color: $neutralBlack;
            text-transform: none;
            letter-spacing: normal;
          }
        }
      }
    }

    .sort-marker {
      .line {
        @include respond($break50) {
          position: absolute;
          top: 0;
          left: 3vw;
          width: 1px;
          height: 100%;
          background-color: $neutral20;
        }

        @include respond($break130) {
          left: 55px;
        }
      }

      .index {
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        font-size: 12px;
        color: $neutral50;
        letter-spacing: 0.054em;

        &::before,
        &::after {
          display: block;
          height: 1px;
          content: "";
          background-color: $neutral20;

          @include respond($break50) {
            display: none;
          }
        }

        &::before {
          width: 50px;
          margin-right: 0.3em;
        }

        &::after {
          width: 100%;
          margin-left: 0.3em;
        }

        @include respond($break50) {
          position: absolute;
          top: 56px;
          left: calc(3vw - 18px);
          display: block;
          width: 38px;
          padding: 0;
          text-align: center;
          background-color: $neutralOffWhite;
          transform: rotate(-90deg);
        }

        @include respond($break130) {
          // 55 - 18px
          left: 36px;
        }
      }
    }
  }

  .scroll-prompt {
    @include trimLink;
    width: 450px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 65px;

    &.disabled {
      opacity: 0;
      transition: opacity 0.5s $timing;
    }

    &.hidden {
      display: none;
    }
  }

  .area + .flash-container {
    padding-left: 6vw;

    @include respond($break70) {
      width: 66.666%;
      padding-left: 42px;
    }
  }

  .flash-container {
    // Specialized layout for no results inside artwork list
    padding-left: 6vw;

    @include respond($break130) {
      padding-left: 110px;
    }
  }
}
