.artwork-filters-active {
  @include containerBase;
  padding: 0 $containerPaddingResp;
  margin-bottom: 30px;

  @include respond($break130) {
    padding: 0 $containerPadding90;
  }

  ul {
    @include listHorizontal;
    line-height: 1.4;

    li {
      display: inline-block;
      line-height: 1;
    }

    button {
      @include buttonUnstyled;
      padding: 0;
      margin-right: 35px;
      font-size: 13px;
      font-weight: $medium;
      letter-spacing: 0.115em;

      .icon-x {
        @include accentSite(color);
        @include iconPre($x);
        display: inline-block;
        margin-left: 2px;
        font-size: 0.769em;
        vertical-align: middle;
      }
    }
  }
}
