// Two column navigation with static hero on the right
.two-column-hero {
  @include containerBase;
  padding: 0 $containerPaddingResp;

  @include respond($break65) {
    display: grid;
    grid-template-areas:
      'header hero'
      'nav hero';
    grid-template-rows: 180px auto;
    grid-template-columns: 45% auto;
    grid-column-gap: 5.5vw;
    padding-top: 12px;
  }

  @include respond($break80) {
    grid-template-rows: 21.565vw auto;
  }

  @include respond($break90) {
    grid-template-columns: 1fr 1fr;
    padding-top: 32px;
  }

  @include respond($break110) {
    grid-template-rows: 248px auto;
  }

  @include respond($break120) {
    grid-column-gap: 80px;
  }

  @include respond($break130) {
    padding: 32px $containerPadding90;
  }

  header {
    @include accentSite(background-color);
    display: flex;
    grid-area: header;
    align-items: center;
    color: $neutralWhite;

    .no-cssgrid & {
      display: block;
      max-width: 650px;
    }

    h1 {
      @include headingSecondary;
      padding: 25px 35px 40px;

      .no-cssgrid & {
        @include respond($break65) {
          padding: 25px 35px 40px;
          margin: 0;
        }

        @include respond($break130) {
          padding: 25px 35px 40px;
        }
      }

      @include respond($break65) {
        margin-top: -1em;
      }

      @include respond($break130) {
        padding: 0 49px;
      }
    }
  }

  figure {
    grid-area: hero;
    max-width: 650px;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin-bottom: 30px;

      @include respond($break65) {
        margin-bottom: 0;
      }

      .no-cssgrid & {
        @include respond($break65) {
          margin-bottom: 30px;
        }
      }
    }

    .caption-primary {
      display: none;
      margin-top: 16px;

      @include respond($break65) {
        display: block;
      }
    }
  }

  nav {
    grid-area: nav;

    @include respond($break50) {
      padding: 10px 0;
    }

    @include respond($break70) {
      padding: 20px 0;
    }

    @include respond($break105) {
      padding: 29px 35px;
    }

    @include respond($break130) {
      padding: 29px 49px;
    }

    ul {
      @include listUnstyled;
    }

    li + li {
      border-top: 1px solid $neutral20;
    }

    a {
      display: block;
      padding: 29px 0 20px;
      font-size: 16px;
      color: $neutralBlack;

      @include respond($break90) {
        font-size: 18px;
      }

      &:hover {
        .name::after {
          opacity: 1;
          transform: translateX(8px);
        }
      }
    }

    .name {
      @include iconPost($arrowRight);
      padding-bottom: 0.389em;
      font-size: 1em;
      font-weight: $bold;

      &::after {
        margin-left: 3px;
        font-size: 9px;
        vertical-align: middle;
        opacity: 0;
        transition: opacity $duration $timing,
          transform $duration $timingBounceBack;
      }
    }

    .location {
      margin: 0;
      font-size: 1em;
    }
  }
}
