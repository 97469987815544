.column-image {
  @include accentFocus(' img');
  position: relative;
  display: block;
  // Required for link text
  color: $neutralBlack;
  text-align: left;
  outline: none;
  // Width class options
  @each $mod, $width in $columnContentWidths {
    &.width-#{$mod} {
      @include respond($break60) {
        max-width: #{$width}px;
      }
    }
  }

  @include respond($break60) {
    max-width: 100%;
  }

  &:hover,
  &:focus-visible {
    .callout {
      .title::after, .label::after {
        transform: translateX(18px);

        @include respond($break90) {
          transform: translateX(0);
        }
      }

      @include respond($break60) {
        opacity: 1;
        transform: translateX(0);

        .left & {
          transform: translateX(0);
        }
      }
    }
  }

  @include respond($break60) {
    display: inline-block;
  }

  // Escape responsive padding until tablet
  &.escape {
    width: calc(100% + #{$containerPaddingResp} * 2);

    .image img {
      width: 100%;
    }

    @include respond($break60) {
      width: 100%;
    }
  }

  &.pad-bottom {
    padding-bottom: 30px;
  }

  .image-link {
    position: relative;
    display: inline-block;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  figcaption {
    @include captionPrimary;
    // Hide on mobile currently
    // TODO: Confirm this
    display: none;
    margin-top: 0.364em;

    @include respond($break60) {
      display: block;
    }
  }

  &.escape {
    img {
      width: 100%;
    }

    figcaption {
      display: none;

      @include respond($break60) {
        display: inherit;
      }
    }
  }

  // Callout
  .callout {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    transition: opacity $duration $timing,
      transform $durationSlow $timing $durationFast;

    @include respond($break60) {
      position: absolute;
      right: -$focusOutlineWidth;
      bottom: 41px;
      left: 35%;
      align-items: flex-start;
      padding: 24px 0 30px 34px;
      background-color: $neutralOffWhite;
      opacity: 0;
      transform: translateX(12px);

      .left & {
        right: 35%;
        left: -$focusOutlineWidth;
        align-items: flex-end;
        padding: 24px 34px 30px 0;
        transform: translateX(-12px);
      }

      .callout-wrapper {
        width: 200px;

        @include respond($break80) {
          width: 300px;
        }

        @include respond($break120) {
          width: 333px;
        }

        .left & {
          padding-right: 0;
          padding-left: 30px;
          text-align: right;
        }

        .callout-content {
          display: inline-block;
          max-width: 100%;
          text-align: left;
        }
      }
    }

    @include respond($break110) {
      left: 65%;

      .left & {
        right: 65%;
      }
    }

    .label, .title {
      @include iconPost($arrowRight);

      &::after {
        margin-left: 10px;
        font-size: 9px;
        vertical-align: middle;
        transition: transform $duration ease-out;
      }
    }

    .label {
      display: block;
      letter-spacing: 0.078em; // OD
      @include labelSecondary;

      @include respond($break60) {
        order: 0;
      }

      &::after {
        @include respond($break60) {
          display: none;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 0.412em;
      font-size: 17px;
      font-weight: $light;
      line-height: 1.2;
      letter-spacing: 0.03em;

      @include respond($break60) {
        order: 1;
      }

      @include respond($break80) {
        font-size: 20px;
      }

      &::after {
        display: none;

        @include respond($break60) {
          display: inline-block;
        }
      }
    }
  }
}
