.artwork-filters-form {
  @include respond($break90) {
    padding-left: 42px;
  }

  .text-search {
    input[type='text'] {
      width: 100%;
    }
  }

  .years {
    .note.under {
      display: none;
      padding-bottom: 13px;
      margin-top: -15px;
      font-size: 11px;
      color: $neutral50;

      @include respond($break70) {
        display: block;
      }

      @include respond($break110) {
        display: none;
      }
    }
  }

  .media {
    .reset.under {
      @include accentSite(color);
      display: none;
      padding-bottom: 13px;
      margin-top: -15px;
      font-size: 12px;
      letter-spacing: 0.054em;

      @include respond($break70) {
        display: block;
      }

      @include respond($break110) {
        display: none;
      }
    }
  }

  .submit {
    width: 100%;
  }

  .suggestions {
    font-size: 12px;
    font-weight: $medium;
    color: $neutral50;

    ul {
      @include listUnstyled;
      display: inline;

      li {
        display: inline;

        &::after {
          display: inline;
          content: ', ';
        }

        &:last-child::after {
          display: none;
        }
      }

      a {
        color: $neutral50;
      }
    }
  }
}

.form-footer {
  padding: 43px 0 0;

  @include respond($break90) {
    padding-left: 42px;
  }

  // CABS
  .arrow-list-vertical {
    display: none;
    border-top: 1px dotted $neutral50;

    @include respond($break70) {
      display: block;
    }

    ul {
      @include listUnstyled;
      padding-top: 12px;

      a {
        @include iconPost($arrowRight);
        display: block;
        font-size: 16px;
        line-height: 2.25;
        color: $neutralBlack;
        transition: opacity $duration $timing;

        @include respond($break70) {
          font-size: 14px;
        }

        @include respond($break80) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.5;

          &::after {
            transform: translateX(12px);
          }
        }

        &::after {
          margin-left: 7px;
          font-size: 9px;
          transition: transform $duration $timing;
        }
      }
    }
  }

  .note {
    margin: -10px 0 0;
    font-size: 14px;
    color: $neutral50;

    @include respond($break40) {
      font-size: 16px;
    }
  }
}
