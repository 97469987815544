.dated-list {
  @include containerBase;
  padding-right: $containerPaddingResp;
  padding-bottom: 30px;
  padding-left: $containerPaddingResp;

  @include respond($break60) {
    padding-bottom: 68px;
  }

  @include respond($break130) {
    padding-right: $containerPadding90;
    padding-left: $containerPadding90;
  }

  .horizontal-nav-reveal + & {
    @include respond($break60) {
      margin-top: 25px;
    }
  }

  .block-nav-secondary + & {
    margin-top: 13px;

    @include respond($break60) {
      margin-top: 25px;
    }
  }

  .list-header-trim {
    @include respond($break60) {
      display: none;
    }
  }

  .container {
    max-width: 730px;
  }

  ul {
    @include listUnstyled;
  }

  li {
    display: flex;
    align-items: flex-start;

    + li {
      margin-top: 19px;
    }
  }

  .date {
    display: flex;
    align-items: flex-end;
    width: 90px;
    min-width: 90px;
    padding-right: 11px;
    font-size: 16px;
    font-weight: $bold;
    white-space: nowrap;

    @include respond($break40) {
      width: 111px;
      min-width: 111px;
    }

    &.empty {
      justify-content: flex-end;

      &::after {
        width: 28px;
      }
    }

    &::after {
      display: block;
      width: 100%;
      height: 1px;
      margin-bottom: 0.5em;
      margin-left: 4px;
      content: '';
      background-color: $neutral20;
    }
  }

  p {
    margin: 0;
    font-size: 14px;

    @include respond($break40) {
      font-size: 16px;
    }
  }
}
