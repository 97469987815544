.year-nav-primary {
  .year-input {
    margin-bottom: 8px;

    @include respond($break60) {
      margin-bottom: 11px;
    }

    .ruler {
      display: none;
    }

    input {
      // Prevent jumps from font-size in IE
      min-height: 33px;
      padding-left: 8px;
      line-height: 1.1em;

      &::placeholder {
        font-size: 11px;
        font-weight: $light;
        color: $neutral40;
        letter-spacing: 0.038em;

        @include respond($break60) {
          font-size: 24px;
        }
      }
    }

    .label {
      display: none;
      margin-top: 13px;
      font-size: 14px;
      font-style: oblique;
      color: $neutral50;

      @include respond($break60) {
        display: block;
      }
    }
  }

  .hero-narrow {
    img {
      max-width: 100%;
      height: auto;
      max-height: 58vh;
    }

    @include respond($break60) {
      display: none;
    }
  }

  .year-preview {
    display: none;

    @include respond($break60) {
      display: block;
    }

    .figure {
      position: absolute;
      display: block;
      width: 100%;

      .no-touchevents &:hover {
        .arrow::after {
          transform: translateX(8px);
        }
      }

      &.preview-enter {
        opacity: 0;
      }

      &.preview-enter-active {
        opacity: 1;
        transition: opacity $durationSlow $timing;
      }

      &.preview-exit {
        opacity: 1;
        transition: opacity $durationSlow $timing;
      }

      &.preview-exit-active {
        opacity: 0;
      }

      img {
        max-width: 100%;
        height: auto;
        max-height: calc(100vh - 237px);

        @include respond($break70) {
          max-height: calc(100vh - 275px);
        }
      }
    }

    figcaption {
      display: none;
      padding-top: 19px;

      @include respond($break60) {
        display: block;
      }

      .label {
        padding-bottom: 1em;
        font-size: 13px;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 0.115em;
      }
    }

    .arrow {
      @include iconPost($arrowRight);
      display: block;
      padding-top: 12px;
      font-size: 15px;
      font-style: italic;
      color: $neutralBlack;

      &::after {
        display: inline-block;
        margin-left: 7px;
        font-size: 9px;
        vertical-align: middle;
        transition: transform $duration $timing;
      }

      @include respond($break60) {
        display: inline-block;
      }
    }
  }

  .year-list {
    @include listUnstyled;

    &:hover {
      a .caption, a .year {
        color: $neutral20;
      }
    }

    li {
      display: block;

      + li {
        a {
          border-top: 1px solid $neutral20;
        }
      }

      @for $i from 1 through length($accentSecondary) {
        &:nth-child(9n + #{$i}) {
          .year span::before {
            background-color: nth($accentSecondary, $i);
          }
        }
      }

      a {
        @include accentFocus;
        display: block;
        padding: 7px 0 11px;
        color: $neutralBlack;

        @include respond($break40) {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 12px 20px 13px 0;
        }

        @include respond($break70) {
          padding: 19px 20px 14px 0;
        }

        &:hover .year {
          color: $neutralBlack;

          span::before {
            opacity: 0.25;
          }
        }

        &:hover .caption {
          color: $neutralBlack;
        }

        &:hover .touch-prompt {
          opacity: 1;

          &::after {
            transform: translateX(8px);
          }
        }
      }
    }

    .year {
      min-width: 124px;
      font-size: 18px;
      font-weight: $black;
      white-space: nowrap;
      transition: color $duration $timing;

      @include respond($break70) {
        font-size: 21px;
      }

      span {
        position: relative;
        display: inline-block;

        &::before {
          position: absolute;
          bottom: 6px;
          left: 0;
          display: block;
          width: 100%;
          height: 13px;
          content: '';
          opacity: 0;
          transition: opacity $duration $timing;
        }
      }
    }

    .caption {
      flex-grow: 1;
      margin-top: -2px;
      font-size: 13px;
      transition: color $duration $timing;

      @include respond($break40) {
        max-width: calc(100% - 124px);
      }

      @include respond($break70) {
        margin: 0;
        font-size: 14px;
      }
    }

    .touch-prompt {
      @include iconPost($arrowRight);
      display: none;
      width: 100%;
      margin-top: -0.2em;
      font-size: 14px;
      font-style: italic;
      color: $neutral50;
      text-align: center;
      opacity: 0;
      transition: opacity $duration $timing;

      .touchevents & {
        display: block;
      }

      &::after {
        margin-left: 7px;
        font-size: 9px;
        transition: transform $durationSlow $timing;
      }
    }
  }
}
