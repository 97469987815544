.column-group-list {
  @include containerBase;
  padding: 0px $containerPaddingResp 45px;

  @include respond($break70) {
    padding-bottom: 10px;
  }

  @include respond($break130) {
    padding: 0px $containerPadding90 10px;
  }

  > ul {
    @include listUnstyled;

    > li {
      padding-top: 16px;

      &:first-child {
        padding-top: 0;
      }

      + li {
        margin-top: 30px;
        border-top: 1px solid $neutral20;
      }
    }
  }

  .group-header {
    display: flex;
    align-items: center;
    padding-bottom: 6px;

    .label {
      font-size: 21px;
      font-weight: $black;

      @include respond($break60) {
        font-size: 24px;
      }
    }

    a {
      @include iconPost($arrowRight);
      @include accentFocus;
      margin-left: 16px;
      font-size: 13px;
      font-style: italic;
      color: $neutralBlack;

      &:hover::after {
        transform: translateX(8px);
      }

      &::after {
        margin-left: 7px;
        font-size: 9px;
        vertical-align: middle;
        transition: transform $duration $timing;
      }
    }
  }

  .wrapper {
    @include respond($break65) {
      display: flex;
    }

    figure {
      width: 300px;
      max-width: 100%;
      padding-bottom: 20px;

      @include respond($break65) {
        min-width: 31.25%;
        max-width: 31.25%;
      }

      @include respond($break90) {
        min-width: 300px;
        max-width: 300px;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        max-height: 70vh;
      }
    }

    .list {
      @include listUnstyled;
      width: 100%;

      @include respond($break40) {
        columns: 2;
        column-gap: 7.5vw;
      }

      @include respond($break65) {
        padding-left: 6.8vw;
      }

      @include respond($break70) {
        column-gap: 10vw;
      }

      @include respond($break90) {
        column-gap: 11vw;
        padding-left: 8vw;
      }

      @include respond($break130) {
        column-gap: 156px;
        padding-left: 138px;
      }

      li {
        @include exhibitionListing;
        margin-bottom: 20px;
        break-inside: avoid;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
