.search-summary {
  @include containerBase;
  padding: 0 $containerPaddingResp;
  padding-bottom: 28px;

  @include respond($break65) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 37px;
  }

  @include respond($break130) {
    padding-right: $containerPadding70;
    padding-bottom: 37px;
    padding-left: $containerPadding70;
  }

  h1 {
    @include headingSecondary;
    padding-right: 35px;
    margin: 0;

    span {
      // TODO: refactor to use site color
      background-image: linear-gradient(nth($accentSecondary, 5), nth($accentSecondary, 5));
      background-repeat: repeat-x;
      background-position: 0 100%;
      background-size: 2px 2px;
    }
  }

  .count {
    display: block;
    margin-top: 8px;
    font-size: 16px;
    white-space: nowrap;

    @include respond($break65) {
      margin-top: 10px;
      font-size: 18px;
    }
  }
}
