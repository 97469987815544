.content-video {
  max-width: calc(1070px + (#{$containerPaddingResp} * 2));
  // Figure with .image wrapper, <img> and optional caption
  // Full width variant does not have this
  padding-right: $containerPaddingResp;
  padding-left: $containerPaddingResp;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  @include respond($break130) {
    max-width: calc(1070px + (#{$containerPadding70} * 2));
    padding-right: $containerPadding70;
    padding-left: $containerPadding70;
  }

  .nested-image & {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &.full {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;

    .caption {
      padding-right: $containerPaddingResp;
      padding-left: $containerPaddingResp;

      @include respond($break130) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &.focus {
    max-width: calc(#{$containerWidthFocus} + (#{$containerPaddingResp} * 2));

    @include respond($break130) {
      max-width: calc(#{$containerWidthFocus} + (#{$containerPadding70} * 2));
    }
  }

  iframe {
    width: 100%;
  }

  video {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto;
  }

  .caption {
    @include captionPrimary;
    margin-top: 1.1em;
    text-align: left;
  }
}
