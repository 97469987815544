.pagination-input {
  @include containerBase;
  height: 8px;
  padding: 0 $containerPaddingResp;
  transition: opacity $durationSlow $timing $duration;

  @include respond($break70) {
    height: 30px;
  }

  @include respond($break130) {
    padding: 0 $containerPadding90;
  }

  .footer-pagination & {
    height: auto;
    padding-bottom: 15px;

    @include respond($break70) {
      padding-top: 23px;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: -11px;
    }
  }

  // Weird selector to elegantly hide when hamburger/search is open
  .hamburger-open ~ main &,
  .search-open ~ main & {
    opacity: 0;
    transition: opacity $duration $timing;
  }

  &.pinned .wrapper {
    position: fixed;

    @include respond($break70) {
      top: 81px;
      width: 100%;
    }

    @include respond($break90) {
      top: 89px;
    }

    @include respond($break130) {
      top: 101px;
    }
  }

  .wrapper {
    position: fixed;
    display: none;
    align-items: center;
    width: 100%;
    transition: top $duration $timing;

    @include respond($break70) {
      position: absolute;
      bottom: auto;
      left: auto;
      display: flex;
      justify-content: flex-start;
      width: auto;
      padding: 0;
      background-color: transparent;
    }

    .footer-pagination & {
      position: static;
      display: flex;
      justify-content: center;

      @include respond($break70) {
        width: 100%;
      }
    }
  }

  .prev,
  .next {
    @include accentFocus;
    font-size: 11px;
    color: $neutralBlack;

    @include respond($break70) {
      font-size: 9px;
    }

    &.disabled {
      color: $neutral40;
    }
  }

  .prev {
    @include iconPre($arrowShortLeft);
  }

  .next {
    @include iconPre($arrowShortRight);
  }

  .ordinals {
    position: relative;
    display: flex;
    padding: 0 9px;
    font-size: 17px;
    cursor: pointer;

    @include respond($break70) {
      font-size: 16px;
    }

    @include respond($break90) {
      font-size: 18px;
    }

    .underline {
      position: absolute;
      bottom: 0;
      left: 13px;
      // Default width for 1 digit, gets reset with JS
      width: 0.6em;
      border-bottom: 1px dotted $neutral50;
      opacity: 0;
      transition: opacity $duration $timing;
    }

    &:hover {
      input {
        color: $neutral40;

        &::placeholder {
          color: $neutral40;
        }
      }

      .underline {
        opacity: 1;
      }
    }

    input {
      display: inline-block;
      // Default for 1 digit, gets reset with JS
      width: 1em;
      min-width: 1em;
      padding-right: 0.28em;
      text-align: right;
      background: transparent;
      border: none;
      border-radius: 0;
      outline: none;
      appearance: none;

      &:focus {
        &::placeholder {
          color: $neutral40;
        }

        ~ .underline {
          opacity: 1;
        }
      }

      &::placeholder {
        color: $neutralBlack;
      }
    }
  }
}
