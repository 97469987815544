.search-results {
  @mixin searchNavGrid {
    > ul {
      @include listUnstyled;

      @include respond($break50) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -32px;
        margin-left: -4vw;
      }

      @include respond($break130) {
        margin-left: -65px;
      }

      > li {
        @include respond($break50) {
          width: 50%;
          max-width: 50%;
          padding-left: 4vw;
          margin-bottom: 32px;
        }

        @include respond($break90) {
          width: 33.333%;
          max-width: 33.333%;
        }

        @include respond($break130) {
          padding-left: 65px;
        }

        + li {
          margin-top: 30px;

          @include respond($break50) {
            margin-top: 0;
          }
        }
      }
    }
  }
  margin-top: 22px;

  @include respond($break60) {
    margin-top: 28px;
  }

  @include respond($break70) {
    margin-top: 14px;
  }

  .search-section {
    + .search-section {
      padding-top: 45px;

      @include respond($break50) {
        padding-top: 60px;
      }

      @include respond($break80) {
        padding-top: 78px;
      }
    }

    header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: 14px;
      margin-bottom: 22px;
      border-bottom: 1px solid $neutral20;

      h2 {
        @include headingPrimary;
        @include accentSite(color, 11);
      }

      // CABS
      .arrow-link {
        @include iconPost($arrowRight);
        @include accentFocus;
        font-size: 13px;
        font-weight: $bold;
        color: $neutralBlack;
        text-transform: uppercase;

        &::after {
          display: inline-block;
          margin-left: 5px;
          font-size: 9px;
          vertical-align: middle;
        }
      }

      .label {
        @include captionPrimary;
        font-size: 12px;

        @include respond($break60) {
          font-size: 14px;
        }
      }
    }

    .lead {
      @include headingSecondary;
      text-align: center;
    }

    .reset-link {
      @include accentSite(color, 22);
      @include accentFocus;
      display: block;
      margin-top: 25px;
      font-size: 18px;
      text-align: center;
    }
  }

  .search-highlight {
    display: inline;
    background-color: transparentize(nth($accentSecondary, 2), 0.5);
  }

  .search-nav-artwork {
    @include searchNavGrid;

    figure {
      width: 100%;

      img {
        max-width: 100%;
        height: auto
      }
    }

    a {
      @include accentFocus(' img');
    }

    .caption {
      @include captionPrimary;
    }

    .group {
      padding-top: 5px;
      font-size: 18px;
      color: $neutralBlack;

      .count {
        @include labelPrimary;
        display: block;
        font-size: 11px;
        color: $neutral50;
      }
    }

    .full {
      font-size: 18px;

      .title {
        padding-bottom: 0.611em;
        font-size: 1em;
        font-style: italic;
        font-weight: $regular;
      }

      .meta {
        .primary {
          padding-bottom: 2px;
          margin: 0;
          font-size: 0.833em;
          line-height: 1.4em;

          @include respond($break50) {
            font-size: 15px;
          }
        }
      }

      .categories {
        @include listUnstyled;
        font-size: 12px;

        a, li {
          display: inline;
          width: auto;
          color: $neutral50;
        }
      }
    }
  }

  .search-nav-chronology {
    ul {
      @include listUnstyled;

      li + li {
        margin-top: 28px;
      }

      a {
        @include accentFocus;
        color: $neutralBlack;

        @include respond($break90) {
          display: flex;
        }

        .date {
          display: block;
          padding-bottom: 0.2em;
          font-size: 20px;
          font-weight: $black;

          @include respond($break50) {
            min-width: 138px;
            max-width: 138px;
            font-size: 22px;
          }

          @include respond($break110) {
            min-width: 160px;
            max-width: 160px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .search-nav-exhibition {
    @include searchNavGrid();
    @include exhibitionListing;
  }

  .search-nav-gallery {
    ul {
      @include listUnstyled;
    }

    li {
      margin-bottom: 18px;
    }

    a {
      display: block;
      padding: 0 0 20px;
      font-size: 16px;
      color: $neutralBlack;

      @include respond($break90) {
        font-size: 18px;
      }

      &:hover {
        .name::after {
          opacity: 1;
          transform: translateX(8px);
        }
      }
    }

    .name {
      @include iconPost($arrowRight);
      padding-bottom: 0.389em;
      font-size: 1em;
      font-weight: $bold;

      &::after {
        margin-left: 3px;
        font-size: 9px;
        vertical-align: middle;
        opacity: 0;
        transition: opacity $duration $timing,
          transform $duration $timingBounceBack;
      }
    }

    .location {
      margin: 0;
      font-size: 1em;
    }
  }

  .search-nav-achievement {
    .dated-list {
      padding-left: 0;
    }

    li + li {
      margin-top: 19px;
    }

    .achievement-type {
      padding-left: 90px;
      font-size: 12px;
      font-weight: $medium;
      color: $neutral50;

      @include respond($break40) {
        padding-left: 111px;
      }
    }
  }
}
